import {
  couleurBordurePrincipal,
  couleurPrincipalBleu,
  largeurRef,
  redimHauteur,
  redimLargeur
} from '../../../libs/utils';
import { StyleSheet } from 'react-native-web';

export const raw = {
  entete: {
    height: redimHauteur(96),
    width: redimLargeur(largeurRef),
    backgroundColor: couleurPrincipalBleu,
    borderBottomWidth: redimLargeur(1),
    borderBottomColor: couleurBordurePrincipal,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
};

export default StyleSheet.create(raw);
