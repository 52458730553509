import React, { Component } from 'react';
import { Text } from 'react-native-web';
import styles from './style';
import ModalBase from '../../base/rendu';

export default class ModalAlerteConfirmation extends Component {
  render () {
    return (
            <ModalBase
                visible={this.props.visible}
                onValidate={this.props.onValidate}
                chargement={this.props.chargement}
                labelAnnulation={this.props.labelAnnulation}
                labelValidation={this.props.labelValidation}
                onCancel={this.props.onCancel}
                taille={this.props.taille}
                onHide={this.props.onHide}
                variant={this.props.variant}
                titre={this.props.titre}
            >
                <Text style={styles.texte}>{this.props.children}</Text>
            </ModalBase>
    );
  }
}
