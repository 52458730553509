const initialState = {
  styleURL: null
};

export default (state = initialState, action) => {
  if (
    action.reference &&
    action.reference.toUpperCase() === 'LAYER'
  ) {
    switch (action.type) {
      case 'UPDATE':
        return { styleURL: action.styleURL };

      default:
        return state;
    }
  }

  return state;
};
