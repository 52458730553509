const initialState = {
  bbox: Date.now(),
  point: Date.now()
};

export default (state = initialState, action) => {
  const timestamp = Date.now();
  switch (action.reference) {
    case 'TIMESTAMP':
      switch (action.type) {
        case 'BBOX':
          return { ...state, bbox: timestamp, point: timestamp };

        case 'POINT':
          return { ...state, point: timestamp };

        default:
          return state;
      }
    default:
      return state;
  }
};
