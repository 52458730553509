import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurSecondaireBlanc,
  largeurRef,
  redimHauteur,
  redimLargeur
} from '../../../../libs/utils';

export const raw = {
  cacher: {
    display: 'none'
  },
  conteneur_filtre: {
    justifyContent: 'space-between',
    width: redimLargeur(largeurRef),
    height: redimHauteur(56),
    backgroundColor: couleurSecondaireBlanc,
    borderRightWidth: redimLargeur(1),
    borderRightColor: couleurBordurePrincipal,
    zIndex: 1000,
    flexDirection: 'row'
  },
  contenu_filtre: {
    flexDirection: 'row',
    width: redimLargeur(largeurRef)
  },
  conteneur_filtre_bouton: {
    borderRadius: 0,
    height: redimHauteur(56),
    width: redimLargeur(128 + 48),
    padding: 0,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: redimHauteur(1),
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderColor: couleurBordurePrincipal
  },
  conteneur_filtre_bouton_texte: {
    fontSize: redimHauteur(18),
    color: couleurPrincipalBlanc,
    lineHeight: 0,
    textTransform: 'uppercase'
  },
  filtre_icone: {
    paddingRight: redimLargeur(6)
  }
};

export default StyleSheet.create(raw);
