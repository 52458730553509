import React, { Component } from 'react';
import styles, { raw } from './style';
import { Text } from 'react-native-web';
import Entete from './item/entete/rendu';
import Valeur from './item/valeur/rendu';
import { Button, Modal } from 'react-bootstrap';

export default class ReactModal extends Component {
  static Entete = Entete;
  static Valeur = Valeur;
  styles;

  render () {
    return (
      <Modal
        size={'lg'}
        show={this.props.isVisible}
        onShow={this.props.onShow}
        style={raw.conteneurTransparent}>
          {
            this.props.titre
              ? <Modal.Header>
              <Modal.Title>
                <Text style={styles.texte}>{this.props.titre}</Text>
              </Modal.Title>
            </Modal.Header>
              : null
          }
          <Modal.Body>{this.props.children}</Modal.Body>
          <Modal.Footer>
            {this.props.boutonUnique
              ? (
              <Button
                variant={'secondary'}
                onClick={this.props.onHide}>

                <Text style={styles.boutonTexte}>
                  Retour
                </Text>
              </Button>
                )
              : (
                  [
                <Button
                  variant={'danger'}
                  onClick={() => {
                    if (typeof this.props.onCancel === 'function') {
                      this.props.onCancel();
                    }

                    if (typeof this.props.onHide === 'function') {
                      this.props.onHide();
                    }
                  }} >
                  <Text style={styles.boutonTexte}>
                    Annuler
                  </Text>
                </Button>,
                <Button
              variant={'primary'}
                  disabled={this.props.validationDisabled}
                  onClick={() => {
                    if (typeof this.props.onValidate === 'function') {
                      this.props.onValidate();
                    }

                    if (typeof this.props.onHide === 'function') {
                      this.props.onHide();
                    }
                  }}>
                  <Text style={styles.boutonTexte}>
                    Valider
                  </Text>
                </Button>
                  ]
                )}
          </Modal.Footer>
      </Modal>
    );
  }
}
