import React, { Component } from 'react';
import styles from './style';
import { Text, View } from 'react-native-web';

export default class Entete extends Component {
  render () {
    return (
      <View style={styles.conteneur}>
        <Text style={styles.texte}>{this.props.children}</Text>
      </View>
    );
  }
}
