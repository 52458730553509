import { Buffer } from 'buffer';
import { Workbook } from 'exceljs';
import StringBuf from './string-buf';

class StringChunk {
  constructor (data, encoding) {
    this._data = data;
    this._encoding = encoding;
  }

  get length () {
    return this.toBuffer().length;
  }

  // copy to target buffer
  copy (target, targetOffset, offset, length) {
    return this.toBuffer().copy(target, targetOffset, offset, length);
  }

  toBuffer () {
    if (!this._buffer) {
      this._buffer = Buffer.from(this._data, this._encoding);
    }
    return this._buffer;
  }
}

class StringBufChunk {
  constructor (data) {
    this._data = data;
  }

  get length () {
    return this._data.length;
  }

  // copy to target buffer
  copy (target, targetOffset, offset, length) {
    // eslint-disable-next-line no-underscore-dangle
    return this._data._buf.copy(target, targetOffset, offset, length);
  }

  toBuffer () {
    return this._data.toBuffer();
  }
}

class BufferChunk {
  constructor (data) {
    this._data = data;
  }

  get length () {
    return this._data.length;
  }

  // copy to target buffer
  copy (target, targetOffset, offset, length) {
    this._data.copy(target, targetOffset, offset, length);
  }

  toBuffer () {
    return this._data;
  }
}

export function load (data, options) {
  if (options === undefined) {
    options = {};
  }

  const workbook = new Workbook();

  const zipStream = workbook.xlsx.createInputStream();

  const writeFn = async function (data, encoding, callback) {
    if (encoding instanceof Function) {
      callback = encoding;
      encoding = 'utf8';
    }

    const nop = () => {};

    callback = callback || nop;

    // encapsulate data into a chunk
    let chunk;
    if (data instanceof StringBuf) {
      chunk = new StringBufChunk(data);
    } else if (data instanceof Buffer) {
      chunk = new BufferChunk(data);
    } else if (
      typeof data === 'string' ||
      data instanceof String ||
      data instanceof ArrayBuffer
    ) {
      chunk = new StringChunk(data, encoding);
    } else {
      throw new Error('Chunk must be one of type String, Buffer or StringBuf.');
    }

    // now, do something with the chunk
    if (this.pipes.length) {
      if (this.batch) {
        this._writeToBuffers(chunk);
        while (!this.corked && this.buffers.length > 1) {
          this._pipe(this.buffers.shift());
        }
      } else if (!this.corked) {
        await this._pipe(chunk);
        callback();
      } else {
        this._writeToBuffers(chunk);
        process.nextTick(callback);
      }
    } else {
      if (!this.paused) {
        this.emit('data', chunk.toBuffer());
      }

      this._writeToBuffers(chunk);
      this.emit('readable');
    }

    return true;
  };

  zipStream.stream.write = writeFn;
  zipStream.stream.write.bind(zipStream.stream);

  return new Promise((resolve, reject) => {
    zipStream
      .on('done', () => {
        resolve(workbook);
      })
      .on('error', error => {
        reject(error);
      });

    if (options.base64) {
      const buffer = Buffer.from(data.toString(), 'base64');
      zipStream.write(buffer);
    } else {
      zipStream.write(data);
    }
    zipStream.end();
  });
}
