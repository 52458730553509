import { StyleSheet } from 'react-native-web';
import { couleurBordurePrincipal, couleurPrincipalBlanc, redimHauteur, redimLargeur } from '../../../libs/utils';

export const raw = {
  conteneur: {
    display: 'flex',
    flexDirection: 'row'
  },
  contenu_filtre_titre: {
    width: `calc(100% - ${redimHauteur(64)})`
  },
  entete_bouton: {
    height: redimHauteur(64),
    width: redimHauteur(64),
    padding: 0,
    lineHeight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRightColor: couleurBordurePrincipal,
    borderRightWidth: redimLargeur(1),
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: redimLargeur(1),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  entete_icone: {
    fontSize: redimHauteur(28),
    color: couleurPrincipalBlanc
  }
};

export default StyleSheet.create(raw);
