import React, { PureComponent as Component } from 'react';
import Modal from '../../../../../modal/rendu';
import styles, { raw } from './style';
import { ActivityIndicator, Text } from 'react-native-web';
import { Button } from 'react-bootstrap';
import ReactFileInput from '../../../../../fichier/ReactFileInput';
import ModalNom from '../../../modal/nom/rendu';
import ModalLieu from '../../../modal/lieu/rendu';
import ModalTableau from '../tableau/rendu';
import * as SecureStorage from '../../../../../../libs/secure-storage';
import { connect } from 'react-redux';
import Bounds from '../../../../../../libs/bounds';
import * as Jexcel from '../../../../../../libs/jexcel';
import { couleurPrincipalBleu } from '../../../../../../libs/utils';

class ModalCarte extends Component {
  constructor (props) {
    super(props);
    this.state = {
      charger: true,
      isVisible: true,
      visibiliteTableau: false,
      visibiliteLieu: false,
      visibliliteNom: false
    };
  }

  charger (donnees, feuille) {
    SecureStorage.get('carte_liste', [])
      .then(async listeActuel => {
        const element = this.props.item;

        element.bounds = new Bounds();
        element.timestamp = Date.now();

        const existe = await SecureStorage.exists(`carte_${element.nom}`);

        if (!existe) {
          listeActuel.push(element.nom);
        }

        element.points = feuille.slice(1).map(ligne => {
          const numPylone = String(ligne[donnees.point.support].valeur).replace(
            /[^\d]+/g,
            ''
          );

          return {
            nom: ligne[donnees.point.support].valeur,
            x: ligne[donnees.point.x].valeur,
            y: ligne[donnees.point.y].valeur,
            indicePylone: numPylone.length > 0 ? Number(numPylone) : null
          };
        });

        element.lignes = donnees.lignes;

        for (const ligne of element.lignes) {
          for (const point of ligne.points) {
            element.bounds.encapsulate(point);
          }
        }

        for (const point of element.points) {
          element.bounds.encapsulate(point);
        }

        SecureStorage.set(`carte_${element.nom}`, element)
          .then(() => {
            if (!existe) {
              SecureStorage.set('carte_liste', listeActuel)
                .then(() => {
                  this.props.add(element);
                  this.props.updateTimestampPoint();
                })
                .catch(e => {
                  console.error(e);
                });
            } else {
              this.props.update(element);
              this.props.updateTimestampBBOX();
            }
          })
          .catch(e => {
            console.error(e);
          });
      })
      .catch(e => {
        console.error(e);
      });
  }

  changement () {
    SecureStorage.set(`carte_${this.props.item.nom}`, this.props.item).then(
      () => {
        this.props.update(this.props.item);
        this.props.updateTimestampBBOX();
      });
  }

  changementNom (nom) {
    if (nom !== this.props.item.nom) {
      SecureStorage.remove(`carte_${this.props.item.nom}`)
        .then(() =>
          SecureStorage.set(`carte_${nom}`, { ...this.props.item, nom })
        )
        .then(() => SecureStorage.get('carte_liste'))
        .then(liste =>
          SecureStorage.set(
            'carte_liste',
            liste.map(nomActuel =>
              nomActuel !== this.props.item.nom ? nomActuel : nom
            )
          )
        )
        .then(() => {
          const filtre = (this.props.filtre.ouvrage || []).map(item => item !== this.props.item.nom ? item : nom);
          this.props.updateFiltre({ ouvrage: filtre.length > 0 ? filtre : null });
        })
        .then(() => {
          this.props.rename(nom, this.props.item);
          this.props.updateTimestampPoint();
        });
    }
  }

  render () {
    return [
      <Modal
        validationDisabled={
          !this.state.actuel ||
          !this.state.actuel.point ||
          ((!this.state.actuel.nom || !this.state.actuel.lieu) &&
            !this.props.update) ||
          !this.state.actuel.lignes.length
        }
        boutonUnique={!this.props.unique}
        isVisible={
          this.state.isVisible &&
          this.props.isVisible
        }
        titre={'Éditer'}
        onHide={this.props.onHide}>
        {this.state.charger
          ? [
        <Button
          variant={'dark'}
          style={raw.bouton}
          onClick={() => {
            this.setState({ visibiliteNom: true, isVisible: false });
          }}>
          <Text style={styles.boutonTexte}>Nom d'ouvrage</Text>
        </Button>,
        <Button
          variant={'dark'}
          style={raw.bouton}
          onClick={() => {
            this.setState({ visibiliteLieu: true, isVisible: false });
          }}>
          <Text style={styles.boutonTexte}>Lieu</Text>
        </Button>,
        <ReactFileInput onLoad={fichier => {
          this.setState({ charger: false });
          Jexcel.getRecord(fichier)
            .then(worksheets => {
              this.setState({ charger: true });
              if (worksheets.length > 0) {
                this.setState({
                  visibiliteTableau: true,
                  worksheets,
                  isVisible: false
                });
              }
            })
            .catch(e => {
              console.error(e);
              this.setState({ charger: true });
            });
        }}
                        accept={[
                          '.xlsx',
                          '.xls'
                        ]}>
          <Button
            variant={'dark'}
            style={raw.bouton}>
            <Text style={styles.boutonTexte}>Réimporter</Text>
          </Button>
        </ReactFileInput>
            ]
          : <ActivityIndicator size="large" color={couleurPrincipalBleu} /> }
      </Modal>,
      <ModalNom
        isVisible={this.state.visibiliteNom}
        actuel={this.props.item ? this.props.item.nom : null}
        onValidate={nom => {
          this.setState({ visibiliteNom: false, isVisible: true });
          this.changementNom(nom.toUpperCase());
        }}
        onCancel={() => {
          this.setState({ visibiliteNom: false, isVisible: true });
        }}
      />,
      <ModalLieu
        isVisible={this.state.visibiliteLieu}
        actuel={this.props.item ? this.props.item.lieu : null}
        onValidate={lieu => {
          this.setState({ visibiliteLieu: false, isVisible: true });

          this.props.item.lieu = lieu;
          this.changement();
        }}
        onCancel={() => {
          this.setState({ visibiliteLieu: false, isVisible: true });
        }}
      />,
      <ModalTableau
        isVisible={this.state.visibiliteTableau}
        tableau={this.state.worksheets}
        update={true}
        onValidate={donnees => {
          this.setState({ visibiliteTableau: false, isVisible: true });
          for (let i = 0; i < donnees.length; i++) {
            if (
              this.props.item &&
              donnees[i].point !== null &&
              donnees[i].lignes.length > 0
            ) {
              this.charger(donnees[i], this.state.worksheets[i].feuille);
            }
          }
        }}
        onCancel={() => {
          this.setState({ visibiliteTableau: false, isVisible: true });
        }}
      />
    ];
  }
}

const mapStateToProps = state => ({
  carte: state.carte,
  chargement: state.chargement,
  filtre: state.filtre
});

const mapDispatchToProps = dispatch => {
  return {
    add: element =>
      dispatch({
        reference: 'CARTE',
        type: 'ADD',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    delete: element =>
      dispatch({
        reference: 'CARTE',
        type: 'DELETE',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    updateTimestampPoint: () =>
      dispatch({
        reference: 'TIMESTAMP',
        type: 'POINT'
      }),
    updateTimestampBBOX: () =>
      dispatch({
        reference: 'TIMESTAMP',
        type: 'BBOX'
      }),
    update: element =>
      dispatch({
        reference: 'CARTE',
        type: 'UPDATE',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    rename: (nom, element) =>
      dispatch({
        reference: 'CARTE',
        type: 'RENAME',
        nom: typeof nom === 'string' ? nom : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    updateFiltre: element =>
      dispatch({
        reference: 'FILTRE',
        type: 'UPDATE',
        filtre: element
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCarte);
