/* eslint one-var: 0, semi-style: 0, no-buffer-constructor: 0 */

// -- Node modules
import { Helper } from 'dxf';

/**
 * Reads the dxf file and returns an dxf buffer.
 *
 * @function(arg1, arg2)
 * @private
 * @param {String}        the name of the database,
 * @param {Function}      the callback to call at completion,
 * @throws                throws an error if the database doesn't exist,
 *                        is unreachable or is corrupted,
 */
function _readDB (db, callback) {
  // Is db defined?
  if (!db) {
    return callback('This database doesn\'t exist!');
  }

  // Ok. Is this a valid database?
  // eslint-disable-next-line no-undef
  const fileReader = new FileReader();
  fileReader.onload = () => {
    /* istanbul ignore next */
    if (!fileReader.result) {
      return callback('This database seems corrupted!');
    }

    callback(null, fileReader.result);
  };

  fileReader.readAsText(db);
}

/**
 * Reads and decode DXF database contents.
 *
 * @function(arg1, arg2)
 * @private
 * @param {String}        the name of the database,
 * @param {Function}      the callback to call at completion,
 * @returns {Object}      the dxf record inside a Javascript object,
 * @throws                throws an error if the record type isn't decoded or unknown,
 *
 * @description           dxf record
 *
 * DELETED RECORD:
 *   1 byte          T: type (always 0),
 *   4 bytes         L: length of this record in bytes (including the T and L fields),
 *   L−5 bytes       bytes to ignore (content undefined),
 *
 * SKIPPER RECORD:
 *   1 byte          T: type (always 1),
 *   4 bytes         number of bytes in the file, including and starting at this
 *                   record, that contain data for POI enclosed in the given
 *                   rectangle,
 *   4 bytes         X1: longitude coordinate of the west edge of the rectangle,
 *   4 bytes         Y1: latitude coordinate of the south edge of the rectangle,
 *   4 bytes         X2: longitude coordinate of the east edge of the rectangle,
 *   4 bytes         Y2: latitude coordinate of the north edge of the rectangle,
 *
 * SIMPLE POI RECORD:
 *   1 byte          T: type (always 2),
 *   4 bytes         L: length of this record in bytes (including the T and L fields),
 *   4 bytes         X: longitude coordinate of the POI,
 *   4 bytes         Y: latitude coordinate of the POI,
 *   L−13 bytes      Name: zero−terminated ASCII string specifying the name
 *                   of the POI,
 *
 * EXTENDED POI RECORD:
 *   1 byte          T: type (always 3),
 *   4 bytes         L: length of this record in bytes (including the T and L fields),
 *   4 bytes         X: longitude coordinate of the POI,
 *   4 bytes         Y: latitude coordinate of the POI,
 *   P bytes         Name: zero−terminated ASCII string specifying the name
 *                   of the POI,
 *   Q bytes         Unique ID: zero−terminated string specifying the unique ID
 *                   of the POI,
 *   L−P−Q−13 bytes  Extra data: zero−terminated string, not used yet,
 */
function _readDxf (db, callback) {
  // Read the database contents:
  _readDB(db, (err, dxf) => {
    const helper = new Helper(dxf);
    const records = [];

    // error?
    if (err) {
      return callback(err);
    }

    // Parse the records:

    const data = helper.toPolylines();

    for (const polyline of data.polylines) {
      records.push({
        points: polyline.vertices.map(point => {
          return { x: point[0], y: point[1] };
        })
      });
    }

    callback(null, records);
  });
}
/* eslint-enable no-underscore-dangle */

// -- Public methods -----------------------------------------------------------

export function getRecord (db, callback) {
  return new Promise((resolve, reject) => {
    _readDxf(db, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
      if (callback) {
        callback(err, data);
      }
    });
  });
}
