import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurPrincipalVert,
  redimHauteur,
  redimLargeur
} from '../../../libs/utils';

export const raw = {
  contenu_filtre_titre: {
    width: '100%',
    height: redimHauteur(64),
    justifyContent: 'center',
    backgroundColor: couleurPrincipalVert,
    alignItems: 'center',
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: redimLargeur(1)
  },
  contenu_filtre_titre_texte: {
    fontSize: redimHauteur(24),
    textTransform: 'uppercase',
    color: couleurPrincipalBlanc
  }
};

export default StyleSheet.create(raw);
