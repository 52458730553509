import { StyleSheet } from 'react-native-web';
import { couleurBordurePrincipal, couleurPrincipalBlanc, redimHauteur, redimLargeur } from '../../../../libs/utils';

export const raw = {
  entete_titre_texte: {
    color: couleurPrincipalBlanc,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: redimHauteur(24)
  },
  pied_bouton: {
    height: redimHauteur(48),
    width: redimLargeur(128),
    padding: 0,
    lineHeight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    borderWidth: redimLargeur(1),
    borderColor: couleurBordurePrincipal
  },
  pied_bouton_texte: {
    fontSize: redimHauteur(20),
    color: couleurPrincipalBlanc
  },
  cacher: {
    display: 'none'
  },
  chargement_icone: {
    paddingRight: redimLargeur(6)
  }
};

export default StyleSheet.create(raw);
