import React, { Component } from 'react';
import { View } from 'react-native-web';
import styles, { raw } from './style';
import ReactSVG from 'react-svg';
import Logo from '../../../assets/images/logo.svg';

export default class EnteteLogo extends Component {
  render () {
    return (
        <View style={styles.conteneur_logo}>
          <ReactSVG
              src={Logo}
              beforeInjection={svg => {
                svg.setAttribute(
                  'style',
                    `height: ${raw.conteneur_logo.height}; width: ${
                        raw.conteneur_logo.width
                    };`
                );
              }}
          />
        </View>
    );
  }
}
