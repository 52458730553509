import React, { Component, createRef } from 'react';
import { View } from 'react-native-web';

export default class ReactFileInput extends Component {
  importationFichier;

  constructor (props) {
    super(props);

    this.importationFichier = new createRef();
  }

  render () {
    return <View style={this.props.style}>
      <input
        ref={this.importationFichier}
        accept={this.props.accept instanceof Array ? this.props.accept.join(', ') : this.props.accept}
        multiple={this.props.multiple}
        onChange={() => {
          if (
            this.props.onLoad &&
            this.importationFichier.current.files &&
            this.importationFichier.current.files.length > 0
          ) {
            this.props.onLoad(this.props.multiple ? this.importationFichier.current.files : this.importationFichier.current.files[0]);
          }
        }}
        type={'file'}
        style={{ display: 'none' }}
      />
      <View
        onClick={() => {
          if (this.importationFichier.current) {
            this.importationFichier.current.click();
          }
        }}>
        {this.props.children}
      </View>
    </View>;
  }
}
