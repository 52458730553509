import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import Modal from '../../../../modal/rendu';

export default class ModalCouleur extends Component {
  constructor (props) {
    super(props);

    this.state = {
      actuel: this.props.actuel
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.actuel !== this.props.actuel) {
      this.setState({ actuel: nextProps.actuel });
    }

    return true;
  }

  render () {
    return (
      <Modal
        onHide={this.props.onHide}
        scrollable={false}
        isVisible={this.props.isVisible}
        onValidate={() => {
          if (typeof this.props.onValidate === 'function') {
            const actuel = this.state.actuel;
            this.setState({ actuel: this.props.actuel });
            this.props.onValidate(actuel);
          }
        }}
        titre={'Couleur'}
        onCancel={this.props.onCancel}>
        <ChromePicker disableAlpha={true} color={this.state.actuel || undefined} styles={{ default: { picker: { boxShadow: 'none' } } }} width={'100%'} onChangeComplete={couleur => {
          this.setState({ actuel: couleur.hex });
        }} />
      </Modal>
    );
  }
}
