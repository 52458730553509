import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles, { raw } from './style';
import { ActivityIndicator, Text } from 'react-native-web';
import { couleurPrincipalBlanc } from '../../../../libs/utils';

export default class ModalBase extends Component {
  render () {
    return (
        <Modal
            show={this.props.visible}
            centered
            size={
              typeof this.props.taille === 'number'
                ? `w${Math.max(0, Math.min(this.props.taille))}`
                : this.props.taille
            }
            onHide={() => {
              if (this.props.onHide && !this.props.chargement) {
                this.props.onHide();
              }
            }}
        >
          <Modal.Header
              bsPrefix={`bg-${this.props.variant || 'primary'} modal-header`}
          >
            <Modal.Title>
              <Text style={styles.entete_titre_texte}>{this.props.titre}</Text>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.children}</Modal.Body>
          <Modal.Footer>
            <Button
                variant="secondary"
                style={raw.pied_bouton}
                disabled={this.props.chargement}
                onClick={() => {
                  if (this.props.onHide) {
                    this.props.onHide();
                  }

                  if (this.props.onCancel) {
                    this.props.onCancel();
                  }
                }}
            >
              <Text style={styles.pied_bouton_texte}>
                {this.props.labelAnnulation || 'Annuler'}
              </Text>
            </Button>
            {this.props.boutons
              ? (
                  this.props.boutons.map(bouton => {
                    return (
                      <Button
                          variant={bouton.variant}
                          style={{
                            ...raw.pied_bouton,
                            ...(bouton.validationActif !== null &&
                            bouton.validationActif !== undefined &&
                            !bouton.validationActif
                              ? raw.cacher
                              : null)
                          }}
                          onClick={() => {
                            if (bouton.onHide) {
                              bouton.onHide();
                            } else if (this.props.onHide) {
                              this.props.onHide();
                            }

                            if (bouton.onValidate) {
                              bouton.onValidate();
                            }
                          }}
                      >
                        <ActivityIndicator
                            style={[
                              styles.chargement_icone,
                              !bouton.chargement
                                ? styles.cacher
                                : null
                            ]}
                            color={couleurPrincipalBlanc}
                        />
                        <Text style={styles.pied_bouton_texte}>
                          {bouton.labelValidation || 'Valider'}
                        </Text>
                      </Button>
                    );
                  })
                )
              : (
                <Button
                    variant={this.props.variant}
                    style={{
                      ...raw.pied_bouton,
                      ...(this.props.validationActif !== null &&
                      this.props.validationActif !== undefined &&
                      !this.props.validationActif
                        ? raw.cacher
                        : null)
                    }}
                    onClick={() => {
                      if (this.props.onHide) {
                        this.props.onHide();
                      }

                      if (this.props.onValidate) {
                        this.props.onValidate();
                      }
                    }}
                >
                  <ActivityIndicator
                      style={[
                        styles.chargement_icone,
                        !this.props.chargement ? styles.cacher : null
                      ]}
                      color={couleurPrincipalBlanc}
                  />
                  <Text style={styles.pied_bouton_texte}>
                    {this.props.labelValidation || 'Valider'}
                  </Text>
                </Button>
                )}
          </Modal.Footer>
        </Modal>
    );
  }
}
