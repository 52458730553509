export default function gestion (reference) {
  const initialState = {
    liste: [],
    donnees: {}
  };

  return (state = initialState, action) => {
    if (
      action.reference &&
      action.reference.toUpperCase() === reference.toUpperCase()
    ) {
      let liste;
      let donnees;
      const nomActuel = action
        ? action.donnees
          ? action.donnees.nom
          : action.nom
        : null;

      switch (action.type) {
        case 'ADD':
          liste = [...state.liste, nomActuel];
          donnees = { ...state.donnees, [nomActuel]: { ...action.donnees } };
          return { liste, donnees };

        case 'DELETE':
          liste = state.liste.filter(nom => nomActuel !== nom);
          donnees = { ...state.donnees };
          delete donnees[nomActuel];
          return { liste, donnees };

        case 'UPDATE':
          donnees = { ...state.donnees, [nomActuel]: { ...action.donnees } };
          return { liste: state.liste, donnees };

        case 'RENAME':
          if (nomActuel !== action.nom) {
            liste = state.liste.map(element => {
              return element !== nomActuel ? element : action.nom;
            });
            donnees = {
              ...state.donnees,
              [action.nom]: { ...action.donnees, nom: action.nom }
            };
            delete donnees[nomActuel];

            return { liste, donnees };
          }

        // eslint-disable-next-line no-fallthrough
        default:
          return state;
      }
    }

    return state;
  };
}
