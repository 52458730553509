import React, { Component } from 'react';
import { randomColor } from 'randomcolor';
import { connect } from 'react-redux';
import Modification from '../../../elements/pages/htb/modification/rendu';
import * as Jexcel from '../../../../libs/jexcel';
import ModificationTitre from '../../../elements/modificationTitre/rendu';
import ModalCouleur from '../modal/couleur/rendu';
import ModalTableau from './modal/tableau/rendu';
import * as SecureStorage from '../../../../libs/secure-storage';
import { faPaintBrush, faTimes } from '@fortawesome/free-solid-svg-icons';
import ModalAlerteConfirmation from '../../../elements/modal/alerte/confirmation/rendu';

class GestionnaireUrgence extends Component {
  textInput;

  constructor (props) {
    super(props);
    this.textInput = React.createRef();

    this.state = {
      charger: true,
      item: null,
      visibiliteTableau: false,
      visibiliteLieu: false,
      visibliliteCouleur: false,
      visibliliteNom: false,
      visibiliteSuppression: false
    };
  }

  charger (donnees, feuilles) {
    const liste = [...this.props.urgence.liste];
    let promise = SecureStorage.set('urgence_liste', []);
    const tableAncien = new Map();

    for (const nom of liste) {
      if (nom !== null) {
        tableAncien.set(nom, this.props.urgence.donnees[nom]);
        promise = promise
          .then(() => SecureStorage.remove(`urgence_${nom}`))
          .then(() => this.props.delete(nom));
      }
    }

    promise.then(() => {
      const listeActuel = [];
      const table = new Map();

      for (let i = 0; i < donnees.length; i++) {
        const urgences = feuilles[i].slice(1).map(ligne => {
          const numPylone = String(ligne[donnees[i].pylone].valeur).replace(
            /[^\d]+/g,
            ''
          );
          return {
            priorite: String(ligne[donnees[i].priorite].valeur)
              .toUpperCase()
              .trim(),
            ouvrage: String(ligne[donnees[i].ouvrage].valeur)
              .toUpperCase()
              .trim(),
            programme:
              String(ligne[donnees[i].programme].valeur).length > 0
                ? String(ligne[donnees[i].programme].valeur)
                  .toUpperCase()
                  .trim()
                : null,
            pylone: ligne[donnees[i].pylone].valeur,
            indicePylone: numPylone.length > 0 ? Number(numPylone) : null
          };
        });

        for (const urgence of urgences) {
          if (!table.has(urgence.priorite)) {
            table.set(urgence.priorite, []);
          }

          table.get(urgence.priorite).push(urgence);
        }
      }

      const promises = [];

      for (const elements of table) {
        listeActuel.push(elements[0]);
        const element = {
          nom: elements[0],
          urgences: elements[1],
          timestamp: Date.now(),
          couleur: tableAncien.has(elements[0])
            ? tableAncien.get(elements[0]).couleur
            : randomColor({
              luminosity: 'bright'
            })
        };

        promises.push(
          SecureStorage.set(`urgence_${element.nom}`, element).then(() =>
            this.props.add(element)
          )
        );
      }

      Promise.all(promises)
        .then(() => SecureStorage.set('urgence_liste', listeActuel))
        .then(() => {
          const listeAjout = new Set([...listeActuel, null]);
          const filtre = (this.props.filtre.priorite || []).filter(item =>
            listeAjout.has(item)
          );
          this.props.updateFiltre({
            priorite: filtre.length > 0 ? filtre : null
          });

          this.props.updateTimestampPoint();
        })
        .catch(e => {
          console.error(e);
        });
    });
  }

  supprimer (nom) {
    SecureStorage.remove(`urgence_${nom}`)
      .then(() => SecureStorage.get('urgence_liste'))
      .then(liste =>
        SecureStorage.set(
          'urgence_liste',
          liste.filter(nomActuel => nomActuel !== nom)
        )
      )
      .then(() => {
        const filtre = (this.props.filtre.priorite || []).filter(
          item => item !== nom
        );
        this.props.updateFiltre({ priorite: filtre.length > 0 ? filtre : null });
      })
      .then(() => {
        this.props.delete(nom);
        this.props.updateTimestampPoint();
      });
  }

  changement () {
    SecureStorage.set(`urgence_${this.state.item.nom}`, this.state.item).then(
      () => this.props.update(this.state.item)
    );
  }

  getDonnees () {
    const donnees = [];

    for (const nom of this.props.urgence.liste.sort()) {
      if (this.props.urgence.donnees[nom]) {
        donnees.push(this.props.urgence.donnees[nom]);
      }
    }

    return donnees;
  }

  render () {
    return [
      <Modification
        chargement={!this.state.charger}
        actif={this.props.actif}
        onClose={this.props.onClose}
        style={this.props.style}
        onLoad={fichier => {
          this.setState({ charger: false });
          Jexcel.getRecord(fichier)
            .then(worksheets => {
              this.setState({ charger: true });
              if (worksheets.length > 0) {
                this.setState({
                  visibiliteTableau: true,
                  worksheets
                });
              }
            })
            .catch(() => {
              this.setState({ charger: true });
            });
        }}
        accept={['.xlsx', '.xls']}>
        {this.getDonnees().map(item => {
          return (
            <ModificationTitre
              bouton={[
                {
                  icone: faTimes,
                  titre: 'Supprimer',
                  variant: 'danger',
                  onClick: () => {
                    this.setState({ visibiliteSuppression: true, item });
                  }
                },
                {
                  icone: faPaintBrush,
                  titre: 'Couleur',
                  onClick: () => {
                    this.setState({ visibiliteCouleur: true, item });
                  }
                }
              ]}>
              {item.nom}
            </ModificationTitre>
          );
        })}
      </Modification>,
      <ModalCouleur
        isVisible={this.state.visibiliteCouleur}
        actuel={this.state.item ? this.state.item.couleur : null}
        onValidate={couleur => {
          this.setState({ visibiliteCouleur: false });

          this.state.item.couleur = couleur;
          this.changement();
        }}
        onCancel={() => {
          this.setState({ visibiliteCouleur: false });
        }}
      />,
      <ModalTableau
        isVisible={this.state.visibiliteTableau}
        tableau={this.state.worksheets}
        update={Boolean(this.state.item)}
        onValidate={donnees => {
          this.setState({ visibiliteTableau: false });
          const liste = [];
          const feuilles = [];
          for (let i = 0; i < donnees.length; i++) {
            if (
              donnees[i].ouvrage !== null &&
              donnees[i].priorite !== null &&
              donnees[i].programme !== null &&
              donnees[i].pylone !== null
            ) {
              liste.push(donnees[i]);
              feuilles.push(this.state.worksheets[i].feuille);
            }
          }

          this.charger(liste, feuilles);
        }}
        onCancel={() => {
          this.setState({ visibiliteTableau: false });
        }}
      />,
      <ModalAlerteConfirmation
        visible={this.state.visibiliteSuppression}
        onValidate={() => {
          this.supprimer(this.state.item.nom);
        }}
        onHide={() => {
          this.setState({ visibiliteSuppression: false });
        }}
        titre={'Suppression'}
        labelValidation={'Supprimer'}
        variant={'danger'}>
        Supprimer la priorité {this.state.item ? this.state.item.nom : ''} ?
      </ModalAlerteConfirmation>
    ];
  }
}

const mapStateToProps = state => ({
  urgence: state.urgence,
  chargement: state.chargement,
  filtre: state.filtre
});

const mapDispatchToProps = dispatch => {
  return {
    add: element =>
      dispatch({
        reference: 'URGENCE',
        type: 'ADD',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    updateTimestampPoint: () =>
      dispatch({
        reference: 'TIMESTAMP',
        type: 'POINT'
      }),
    update: element =>
      dispatch({
        reference: 'URGENCE',
        type: 'UPDATE',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    delete: element =>
      dispatch({
        reference: 'URGENCE',
        type: 'DELETE',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    updateFiltre: element =>
      dispatch({
        reference: 'FILTRE',
        type: 'UPDATE',
        filtre: element
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionnaireUrgence);
