import { StyleSheet } from 'react-native-web';
import { hauteurRef, largeurRef, redimHauteur, redimLargeur } from '../../../libs/utils';

export const raw = {
  disparaitre: {
    visibility: 'hidden'
  },
  conteneur: {
    height: redimHauteur(hauteurRef - 96),
    width: redimLargeur(largeurRef),
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexWrap: 'wrap'
  }
};

export default StyleSheet.create(raw);
