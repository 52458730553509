import PouchDB from 'pouchdb';

export function init () {
  global.database = new PouchDB('haute-tension-b-db');
}

export async function get (nom, valeurParDefault = null) {
  return global.database.get(nom).then(doc => doc.data).catch(() => valeurParDefault);
}

export async function exists (nom) {
  return global.database.get(nom).then(() => true).catch(() => false);
}

export async function remove (nom) {
  return global.database.get(nom).then(doc => global.database.remove(doc));
}

export async function set (nom, item) {
  return global.database.get(nom).catch(() => ({ _id: nom })).then(doc => global.database.put({ ...doc, data: item }));
}
