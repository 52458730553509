import React, { Component } from 'react';
import styles, { raw } from './style';
import { Text, View } from 'react-native-web';
import TextInput from 'react-native-web/dist/exports/TextInput';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import { redimHauteur, redimLargeur } from '../../../libs/utils';
import Select from 'react-select';
import dateAndTime from 'date-and-time';

export default class FiltreItem extends Component {
  constructor (props) {
    super(props);
    this.state = {
      indiceElement: -1,
      positionType: 'dd',
      focus: false,
      positionDirectionV: [null, null, null, 'N'],
      positionDirectionH: [null, null, null, 'E']
    };
  }

  parseDMS (xLon, yLat) {
    if (!xLon) {
      return {
        longitude: null,
        latitude: null
      };
    }

    const x =
        (Number(xLon[0] || 0) +
            Number(xLon[1] || 0) / 60 +
            Number(xLon[2] || 0) / (60 * 60)) *
        (xLon[3].match(/^[WS]$/) ? -1 : 1);

    if (!yLat) {
      if (xLon[3].match(/^[NS]$/)) {
        return {
          longitude: null,
          latitude: x
        };
      } else {
        return {
          longitude: x,
          latitude: null
        };
      }
    }

    const y =
        (Number(yLat[0] || 0) +
            Number(yLat[1] || 0) / 60 +
            Number(yLat[2] || 0) / (60 * 60)) *
        (yLat[3].match(/^[WS]$/) ? -1 : 1);

    return {
      longitude: x,
      latitude: y
    };
  }

  getDMS (xLon, yLat) {
    let y = yLat || 0;
    let x = xLon || 0;

    const xDirection = Math.sign(x) < 0 ? 'W' : 'E';
    x = Math.abs(x);
    const xDegree = Math.floor(x);
    x -= xDegree;
    const xMinute = Math.floor(x * 60);
    x -= xMinute / 60;
    const xSeconde = x / (60 * 60);

    const yDirection = Math.sign(y) < 0 ? 'S' : 'N';
    y = Math.abs(y);
    const yDegree = Math.floor(y);
    y -= yDegree;
    const yMinute = Math.floor(y * 60);
    y -= yMinute / 60;
    const ySeconde = y / (60 * 60);

    return {
      longitude: [xDegree, xMinute, xSeconde, xDirection],
      latitude: [yDegree, yMinute, ySeconde, yDirection]
    };
  }

  renduParType (
    propsType,
    propsValeur,
    propsOnChange,
    propsStyle,
    propsOptions
  ) {
    // eslint-disable-next-line no-undef
    const dateTimeFormat = new Intl.DateTimeFormat('fr', {
      month: 'long',
      year: 'numeric'
    });

    const CustomInput = ({ value, onClick }) => (
        <Button
            style={{ ...raw.contenu_filtre_valeur, ...raw.date_picker }}
            onClick={onClick}
        >
          <Text
              style={[
                styles.date_picker_texte,
                propsOptions && propsOptions.unique
                  ? styles.date_picker_single_texte
                  : null
              ]}
          >
            {propsOptions && propsOptions.parMois && value
              ? `${dateTimeFormat.format(
                    dateAndTime.parse(value, 'DD/MM/YYYY'))
                    .slice(0, 1)
                    .toUpperCase()}${dateTimeFormat.format(
                    dateAndTime.parse(value, 'DD/MM/YYYY'))
                    .slice(1)
                    .toLowerCase()}`
              : value}
          </Text>
        </Button>
    );

    switch (propsType) {
      case 'position':
        if (
          (propsValeur[0] === null || propsValeur[0] === undefined) &&
            ((this.state.positionDirectionH[0] !== null &&
                this.state.positionDirectionH[0] !== undefined &&
                this.state.positionDirectionH[0] !== '') ||
                (this.state.positionDirectionH[1] !== null &&
                    this.state.positionDirectionH[1] !== undefined &&
                    this.state.positionDirectionH[1] !== '') ||
                (this.state.positionDirectionH[2] !== null &&
                    this.state.positionDirectionH[2] !== undefined &&
                    this.state.positionDirectionH[2] !== ''))
        ) {
          this.setState(
            { positionDirectionH: [null, null, null, 'E'] });
        }

        if (
          (propsValeur[1] === null || propsValeur[1] === undefined) &&
            ((this.state.positionDirectionV[0] !== null &&
                this.state.positionDirectionV[0] !== undefined &&
                this.state.positionDirectionV[0] !== '') ||
                (this.state.positionDirectionV[1] !== null &&
                    this.state.positionDirectionV[1] !== undefined &&
                    this.state.positionDirectionV[1] !== '') ||
                (this.state.positionDirectionV[2] !== null &&
                    this.state.positionDirectionV[2] !== undefined &&
                    this.state.positionDirectionV[2] !== ''))
        ) {
          this.setState(
            { positionDirectionV: [null, null, null, 'N'] });
        }

        const tablePositionType = new Map([
          ['dd', 'Degrés décimaux'],
          ['dms', 'Degrés, Minutes, Secondes']
        ]);
        const tablePositionDirectionV = new Map(
          [['N', 'N'], ['S', 'S']]);
        const tablePositionDirectionH = new Map(
          [['W', 'W'], ['E', 'E']]);

        return (
            <View style={styles.contenu_filtre_lignes}>
              <View
                  style={[
                    styles.contenu_filtre_ligne,
                    this.state.indiceElement === 0
                      ? styles.focus
                      : null
                  ]}
                  onClick={() => {
                    this.setState({ indiceElement: 0 });
                  }}
              >
                <View
                    style={[
                      styles.contenu_filtre_nom,
                      styles.contenu_filtre_sous_nom
                    ]}
                >
                  <Text style={styles.contenu_filtre_nom_texte}>Type</Text>
                </View>
                <Select
                    className={`${
                        this.state.indiceElement === 0
                            ? ''
                            : 'selection-sans-focus'
                    } selection selection-position-type`}
                    placeholder={''}
                    value={{
                      value: this.state.positionType,
                      label: tablePositionType.get(this.state.positionType)
                    }}
                    onChange={element => {
                      this.setState(
                        { positionType: element.value });
                    }}
                    options={Array.from(tablePositionType).map(element => {
                      return {
                        value: element[0],
                        label: element[1]
                      };
                    })}
                />
              </View>

              <View
                  style={[
                    styles.contenu_filtre_ligne,
                    this.state.indiceElement === 1 ? styles.focus : null
                  ]}
                  onClick={() => {
                    this.setState({ indiceElement: 1 });
                  }}
              >
                <View
                    style={[
                      styles.contenu_filtre_nom,
                      styles.contenu_filtre_sous_nom
                    ]}
                >
                  <Text style={styles.contenu_filtre_nom_texte}>Latitude</Text>
                </View>
                {this.state.positionType === 'dd'
                  ? this.renduParType(
                    'nombre',
                    propsValeur[1] !== null && propsValeur[1] !== undefined
                      ? propsValeur[1]
                      : '',
                    (texte, valeur) => {
                      if (
                        texte !== null &&
                              texte !== undefined &&
                              texte !== ''
                      ) {
                        const dms = this.getDMS(
                          propsValeur[0], valeur);
                        this.setState(
                          { positionDirectionV: dms.latitude });
                      } else {
                        this.setState({
                          positionDirectionV: [
                            null,
                            null,
                            null,
                            'E']
                        });
                      }

                      propsOnChange([
                        propsValeur[0],
                        texte,
                        propsValeur[2]]);
                    },
                    styles.taille_position_dd
                  )
                  : null}
                {this.state.positionType === 'dms'
                  ? this.renduParType(
                    'nombre',
                    this.state.positionDirectionV[0] !== null &&
                        this.state.positionDirectionV[0] !== undefined
                      ? this.state.positionDirectionV[0]
                      : '',
                    texte => {
                      const positionDirection = this.state.positionDirectionV;

                      const dms = this.getDMS(propsValeur[0],
                        propsValeur[1]);

                      if (!texte || String(texte).match(/^[^-]*$/)) {
                        positionDirection[0] = texte;
                      }

                      let latitude = null;

                      if (
                        (positionDirection[0] !== null &&
                                  positionDirection[0] !== undefined &&
                                  positionDirection[0] !== '') ||
                              (positionDirection[1] !== null &&
                                  positionDirection[1] !== undefined &&
                                  positionDirection[1] !== '') ||
                              (positionDirection[2] !== null &&
                                  positionDirection[2] !==
                                  undefined &&
                                  positionDirection[2] !== '')
                      ) {
                        latitude = this.parseDMS([
                          positionDirection[0],
                          dms.latitude[1],
                          dms.latitude[2],
                          dms.latitude[3]
                        ]).latitude;
                      }
                      this.setState(
                        { positionDirectionV: positionDirection });
                      propsOnChange([
                        propsValeur[0],
                        latitude,
                        propsValeur[2]]);
                    },
                    styles.taille_position_dms
                  )
                  : null}
                {this.state.positionType === 'dms'
                  ? this.renduParType(
                    'nombre',
                    this.state.positionDirectionV[1] !== null &&
                        this.state.positionDirectionV[1] !== undefined
                      ? this.state.positionDirectionV[1]
                      : '',
                    texte => {
                      const positionDirection = this.state.positionDirectionV;

                      const dms = this.getDMS(propsValeur[0],
                        propsValeur[1]);

                      if (!texte || String(texte).match(/^[^-]*$/)) {
                        positionDirection[1] = texte;
                      }

                      let latitude = null;

                      if (
                        (positionDirection[0] !== null &&
                                  positionDirection[0] !== undefined &&
                                  positionDirection[0] !== '') ||
                              (positionDirection[1] !== null &&
                                  positionDirection[1] !== undefined &&
                                  positionDirection[1] !== '') ||
                              (positionDirection[2] !== null &&
                                  positionDirection[2] !== undefined &&
                                  positionDirection[2] !== '')
                      ) {
                        latitude = this.parseDMS([
                          dms.latitude[0],
                          positionDirection[1],
                          dms.latitude[2],
                          dms.latitude[3]
                        ]).latitude;
                      }

                      this.setState(
                        { positionDirectionV: positionDirection });
                      propsOnChange([
                        propsValeur[0],
                        latitude,
                        propsValeur[2]]);
                    },
                    styles.taille_position_dms
                  )
                  : null}
                {this.state.positionType === 'dms'
                  ? this.renduParType(
                    'nombre',
                    this.state.positionDirectionV[2] !== null &&
                        this.state.positionDirectionV[2] !== undefined
                      ? this.state.positionDirectionV[2]
                      : '',
                    texte => {
                      const positionDirection = this.state.positionDirectionV;

                      const dms = this.getDMS(propsValeur[0],
                        propsValeur[1]);

                      if (!texte || String(texte).match(/^[^-]*$/)) {
                        positionDirection[2] = texte;
                      }

                      let latitude = null;

                      if (
                        (positionDirection[0] !== null &&
                                  positionDirection[0] !== undefined &&
                                  positionDirection[0] !== '') ||
                              (positionDirection[1] !== null &&
                                  positionDirection[1] !== undefined &&
                                  positionDirection[1] !== '') ||
                              (positionDirection[2] !== null &&
                                  positionDirection[2] !== undefined &&
                                  positionDirection[2] !== '')
                      ) {
                        latitude = this.parseDMS([
                          dms.latitude[0],
                          dms.latitude[1],
                          positionDirection[2],
                          dms.latitude[3]
                        ]).latitude;
                      }

                      this.setState(
                        { positionDirectionV: positionDirection });
                      propsOnChange([
                        propsValeur[0],
                        latitude,
                        propsValeur[2]]);
                    },
                    styles.taille_position_dms
                  )
                  : null}

                {this.state.positionType === 'dms'
                  ? (
                    <Select
                        className={`${
                            this.state.indiceElement === 1
                                ? ''
                                : 'selection-sans-focus'
                        } selection selection-position-direction`}
                        placeholder={''}
                        value={{
                          value: this.state.positionDirectionV[3],
                          label: tablePositionDirectionV.get(
                            this.state.positionDirectionV[3]
                          )
                        }}
                        onChange={element => {
                          const positionDirection = this.state.positionDirectionV;

                          const dms = this.getDMS(propsValeur[0],
                            propsValeur[1]);

                          positionDirection[3] = element.value;

                          const latitude = this.parseDMS([
                            dms.latitude[0],
                            dms.latitude[1],
                            dms.latitude[2],
                            positionDirection[3]
                          ]).latitude;

                          this.setState(
                            { positionDirectionV: positionDirection });
                          propsOnChange([
                            propsValeur[0],
                            latitude,
                            propsValeur[2]]);
                        }}
                        options={Array.from(tablePositionDirectionV)
                          .map(element => {
                            return {
                              value: element[0],
                              label: element[1]
                            };
                          })}
                    />
                    )
                  : null}
              </View>

              <View
                  style={[
                    styles.contenu_filtre_ligne,
                    this.state.indiceElement === 2 ? styles.focus : null
                  ]}
                  onClick={() => {
                    this.setState({ indiceElement: 2 });
                  }}
              >
                <View
                    style={[
                      styles.contenu_filtre_nom,
                      styles.contenu_filtre_sous_nom
                    ]}
                >
                  <Text style={styles.contenu_filtre_nom_texte}>Longitude</Text>
                </View>
                {this.state.positionType === 'dd'
                  ? this.renduParType(
                    'nombre',
                    propsValeur[0] !== null && propsValeur[0] !== undefined
                      ? propsValeur[0]
                      : '',
                    (texte, valeur) => {
                      if (
                        texte !== null &&
                              texte !== undefined &&
                              texte !== ''
                      ) {
                        const dms = this.getDMS(valeur,
                          propsValeur[1]);
                        this.setState(
                          { positionDirectionH: dms.longitude });
                      } else {
                        this.setState({
                          positionDirectionH: [
                            null,
                            null,
                            null,
                            'E']
                        });
                      }
                      propsOnChange([
                        texte,
                        propsValeur[1],
                        propsValeur[2]]);
                    },
                    styles.taille_position_dd
                  )
                  : null}
                {this.state.positionType === 'dms'
                  ? this.renduParType(
                    'nombre',
                    this.state.positionDirectionH[0] !== null &&
                        this.state.positionDirectionH[0] !== undefined
                      ? this.state.positionDirectionH[0]
                      : '',
                    texte => {
                      const positionDirection = this.state.positionDirectionH;

                      const dms = this.getDMS(propsValeur[0],
                        propsValeur[1]);

                      if (!texte || String(texte).match(/^[^-]*$/)) {
                        positionDirection[0] = texte;
                      }

                      let longitude = null;

                      if (
                        (positionDirection[0] !== null &&
                                  positionDirection[0] !== undefined &&
                                  positionDirection[0] !== '') ||
                              (positionDirection[1] !== null &&
                                  positionDirection[1] !== undefined &&
                                  positionDirection[1] !== '') ||
                              (positionDirection[2] !== null &&
                                  positionDirection[2] !== undefined &&
                                  positionDirection[2] !== '')
                      ) {
                        longitude = this.parseDMS([
                          positionDirection[0],
                          dms.longitude[1],
                          dms.longitude[1],
                          dms.longitude[3]
                        ]).longitude;
                      }

                      this.setState(
                        { positionDirectionH: positionDirection });
                      propsOnChange([
                        longitude,
                        propsValeur[1],
                        propsValeur[2]
                      ]);
                    },
                    styles.taille_position_dms
                  )
                  : null}
                {this.state.positionType === 'dms'
                  ? this.renduParType(
                    'nombre',
                    this.state.positionDirectionH[1] !== null &&
                        this.state.positionDirectionH[1] !== undefined
                      ? this.state.positionDirectionH[1]
                      : '',
                    texte => {
                      const positionDirection = this.state.positionDirectionH;

                      const dms = this.getDMS(propsValeur[0],
                        propsValeur[1]);

                      if (!texte || String(texte).match(/^[^-]*$/)) {
                        positionDirection[1] = texte;
                      }

                      let longitude = null;

                      if (
                        (positionDirection[0] !== null &&
                                  positionDirection[0] !== undefined &&
                                  positionDirection[0] !== '') ||
                              (positionDirection[1] !== null &&
                                  positionDirection[1] !== undefined &&
                                  positionDirection[1] !== '') ||
                              (positionDirection[2] !== null &&
                                  positionDirection[2] !== undefined &&
                                  positionDirection[2] !== '')
                      ) {
                        longitude = this.parseDMS([
                          dms.longitude[0],
                          positionDirection[1],
                          dms.longitude[1],
                          dms.longitude[3]
                        ]).longitude;
                      }

                      this.setState(
                        { positionDirectionH: positionDirection });
                      propsOnChange([
                        longitude,
                        propsValeur[1],
                        propsValeur[2]
                      ]);
                    },
                    styles.taille_position_dms
                  )
                  : null}
                {this.state.positionType === 'dms'
                  ? this.renduParType(
                    'nombre',
                    this.state.positionDirectionH[2] !== null &&
                        this.state.positionDirectionH[2] !== undefined
                      ? this.state.positionDirectionH[2]
                      : '',
                    texte => {
                      const positionDirection = this.state.positionDirectionH;

                      const dms = this.getDMS(propsValeur[0],
                        propsValeur[1]);

                      if (!texte || String(texte).match(/^[^-]*$/)) {
                        positionDirection[2] = texte;
                      }

                      let longitude = null;

                      if (
                        (positionDirection[0] !== null &&
                                  positionDirection[0] !== undefined &&
                                  positionDirection[0] !== '') ||
                              (positionDirection[1] !== null &&
                                  positionDirection[1] !== undefined &&
                                  positionDirection[1] !== '') ||
                              (positionDirection[2] !== null &&
                                  positionDirection[2] !== undefined &&
                                  positionDirection[2] !== '')
                      ) {
                        longitude = this.parseDMS([
                          dms.longitude[0],
                          dms.longitude[1],
                          positionDirection[2],
                          dms.longitude[3]
                        ]).longitude;
                      }

                      this.setState(
                        { positionDirectionH: positionDirection });
                      propsOnChange([
                        longitude,
                        propsValeur[1],
                        propsValeur[2]
                      ]);
                    },
                    styles.taille_position_dms
                  )
                  : null}

                {this.state.positionType === 'dms'
                  ? (
                    <Select
                        className={`${
                            this.state.indiceElement === 2
                                ? ''
                                : 'selection-sans-focus'
                        } selection selection-position-direction`}
                        placeholder={''}
                        value={{
                          value: this.state.positionDirectionH[3],
                          label: tablePositionDirectionH.get(
                            this.state.positionDirectionH[3]
                          )
                        }}
                        onChange={element => {
                          const positionDirection = this.state.positionDirectionH;

                          const dms = this.getDMS(propsValeur[0],
                            propsValeur[1]);

                          positionDirection[3] = element.value;

                          const longitude = this.parseDMS([
                            dms.longitude[0],
                            dms.longitude[1],
                            dms.longitude[2],
                            positionDirection[3]
                          ]).longitude;

                          this.setState(
                            { positionDirectionH: positionDirection });
                          propsOnChange([
                            longitude,
                            propsValeur[1],
                            propsValeur[2]]);
                        }}
                        options={Array.from(tablePositionDirectionH)
                          .map(element => {
                            return {
                              value: element[0],
                              label: element[1]
                            };
                          })}
                    />
                    )
                  : null}
              </View>

              <View style={styles.contenu_filtre_ligne}>
                <View
                    style={[
                      styles.contenu_filtre_nom,
                      styles.contenu_filtre_sous_nom
                    ]}
                >
                  <Text style={styles.contenu_filtre_nom_texte}>Rayon</Text>
                </View>
                {this.renduParType(
                  'nombre',
                  propsValeur[2],
                  texte => {
                    if (propsOnChange) {
                      const valeur = propsValeur;
                      valeur[2] = texte;
                      propsOnChange(valeur);
                    }
                  },
                  styles.taille_position_rayon
                )}

                <View
                    style={[
                      styles.contenu_filtre_nom,
                      styles.taille_position_rayon_suffixe
                    ]}
                >
                  <Text
                      style={[
                        styles.contenu_filtre_nom_texte,
                        styles.taille_position_rayon_suffixe_texte
                      ]}
                  >
                    m
                  </Text>
                </View>
              </View>
            </View>
        );

      case 'date':
        return propsOptions && propsOptions.unique
          ? (
            <View
                style={[
                  styles.contenu_filtre_ligne,
                  this.state.indiceElement === 1 ? styles.focus : {}
                ]}
                onClick={() => {
                  this.setState({ indiceElement: 1 });
                }}
            >
              <DatePicker
                  selected={propsValeur ? new Date(propsValeur) : null}
                  isClearable
                  minDate={propsOptions.min ? new Date(propsOptions.min) : null}
                  maxDate={propsOptions.max ? new Date(propsOptions.max) : null}
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: `${redimLargeur(-64)}, ${redimHauteur(0)}`
                    }
                  }}
                  onChange={valeur => {
                    if (propsOnChange) {
                      propsOnChange(valeur);
                    }
                  }}
                  customInput={<CustomInput/>}
                  dateFormat={'dd/MM/yyyy'}
                  showMonthYearPicker={propsOptions.parMois}
              />
            </View>
            )
          : (
            <View style={styles.contenu_filtre_lignes}>
              {propsOptions && propsOptions.unique
                ? (
                  <View
                      style={[
                        styles.contenu_filtre_ligne,
                        this.state.indiceElement === 0 ? styles.focus : {}
                      ]}
                      onClick={() => {
                        this.setState({ indiceElement: 0 });
                      }}
                  />
                  )
                : (
                    ['Du', 'Au'].map((libelle, indice) => {
                      return (
                        <View
                            style={[
                              styles.contenu_filtre_ligne,
                              this.state.indiceElement === indice
                                ? styles.focus
                                : {}
                            ]}
                            onClick={() => {
                              this.setState(
                                { indiceElement: indice });
                            }}
                        >
                          <View
                              style={[
                                styles.contenu_filtre_nom,
                                styles.contenu_filtre_sous_nom
                              ]}
                          >
                            <Text style={styles.contenu_filtre_nom_texte}>
                              {libelle}
                            </Text>
                          </View>
                          {indice === 0
                            ? (
                              <DatePicker
                                  selected={
                                    propsValeur[0]
                                      ? new Date(propsValeur[0])
                                      : null
                                  }
                                  selectsStart
                                  startDate={
                                    propsValeur[0]
                                      ? new Date(propsValeur[0])
                                      : null
                                  }
                                  endDate={
                                    propsValeur[1]
                                      ? new Date(propsValeur[1])
                                      : null
                                  }
                                  maxDate={
                                    propsValeur[1]
                                      ? new Date(propsValeur[1])
                                      : null
                                  }
                                  isClearable
                                  popperModifiers={{
                                    offset: {
                                      enabled: true,
                                      offset: `${redimLargeur(
                                          -64)}, ${redimHauteur(0)}`
                                    }
                                  }}
                                  onChange={valeur => {
                                    if (propsOnChange) {
                                      propsOnChange([
                                        valeur,
                                        propsValeur[1]
                                          ? new Date(
                                            propsValeur[1])
                                          : null
                                      ]);
                                    }
                                  }}
                                  customInput={<CustomInput/>}
                                  dateFormat={'dd/MM/yyyy'}
                              />
                              )
                            : (
                              <DatePicker
                                  selected={
                                    propsValeur[1]
                                      ? new Date(propsValeur[1])
                                      : null
                                  }
                                  selectsEnd
                                  startDate={
                                    propsValeur[0]
                                      ? new Date(propsValeur[0])
                                      : null
                                  }
                                  endDate={
                                    propsValeur[1]
                                      ? new Date(propsValeur[1])
                                      : null
                                  }
                                  minDate={
                                    propsValeur[0]
                                      ? new Date(propsValeur[0])
                                      : null
                                  }
                                  isClearable
                                  popperModifiers={{
                                    offset: {
                                      enabled: true,
                                      offset: `${redimLargeur(
                                          -64)}, ${redimHauteur(0)}`
                                    }
                                  }}
                                  onChange={valeur => {
                                    if (propsOnChange) {
                                      propsOnChange([
                                        propsValeur[0]
                                          ? new Date(
                                            propsValeur[0])
                                          : null,
                                        valeur
                                          ? valeur.setHours(
                                            23, 59, 59, 999)
                                          : null
                                      ]);
                                    }
                                  }}
                                  customInput={<CustomInput/>}
                                  dateFormat={'dd/MM/yyyy'}
                              />
                              )}
                        </View>
                      );
                    })
                  )}
            </View>
            );

      case 'nombre':
        return (
            <TextInput
                style={[styles.contenu_filtre_valeur, propsStyle]}
                onBlur={() => {
                  let nombre =
                      propsValeur !== null &&
                      propsValeur !== undefined &&
                      propsValeur !== ''
                        ? Number(
                          String(propsValeur).replace(/[-.]+$/, ''))
                        : null;

                  nombre = isNaN(nombre) ? null : nombre;
                  propsOnChange(nombre);
                }}
                onChangeText={val => {
                  if (propsOnChange) {
                    if (val) {
                      val = String(val).replace(',', '.');

                      if (propsOptions) {
                        if (propsOptions.entier) {
                          if (propsOptions.positif) {
                            if (!val.match(/^\d+$/)) {
                              val =
                                  propsValeur !== null &&
                                  propsValeur !==
                                  undefined
                                    ? String(
                                      propsValeur)
                                    : null;
                            }
                          } else if (propsOptions.negatif) {
                            if (!val.match(/^(-|-\d+)$/)) {
                              val =
                                  propsValeur !== null &&
                                  propsValeur !==
                                  undefined
                                    ? String(
                                      propsValeur)
                                    : null;
                            }
                          } else if (!val.match(/^(-|-?\d+)$/)) {
                            val =
                                propsValeur !== null &&
                                propsValeur !== undefined
                                  ? String(propsValeur)
                                  : null;
                          }
                        } else if (propsOptions.positif) {
                          if (!val.match(/^(\.\d*|\d+\.?|\d+\.\d+)$/)) {
                            val =
                                propsValeur !== null &&
                                propsValeur !== undefined
                                  ? String(propsValeur)
                                  : null;
                          }
                        } else if (propsOptions.negatif) {
                          if (!val.match(/^(-|-\.\d*|-\d+\.?|-\d+\.\d+)$/)) {
                            val =
                                propsValeur !== null &&
                                propsValeur !== undefined
                                  ? String(propsValeur)
                                  : null;
                          }
                        } else if (
                          !val.match(/^(-|\.\d*|-\.\d*|-?\d+\.?|-?\d+\.\d+)$/)
                        ) {
                          val =
                              propsValeur !== null &&
                              propsValeur !== undefined
                                ? String(propsValeur)
                                : null;
                        }
                      } else if (
                        !val.match(/^(-|\.\d*|-\.\d*|-?\d+\.?|-?\d+\.\d+)$/)
                      ) {
                        val =
                            propsValeur !== null &&
                            propsValeur !== undefined
                              ? String(propsValeur)
                              : null;
                      }
                    }

                    propsOnChange(val || '');
                  }
                }}
                value={String(
                  propsValeur !== null && propsValeur !== undefined
                    ? propsValeur
                    : ''
                )}
            />
        );

      case 'selection':
        const tableSelection = new Map(
          propsOptions.items.map(item =>
            typeof item !== 'object'
              ? [item, item]
              : [
                  item.valeur,
                  item.nom]
          )
        );

        const items =
            propsValeur !== null &&
            propsValeur !== undefined &&
            (!propsOptions.multi || propsValeur.length > 0)
              ? propsValeur
              : null;

        return (
            <View
                style={[
                  styles.contenu_filtre_selection,
                  this.state.indiceElement === 0 ? styles.focus : {}
                ]}
                onClick={() => {
                  this.setState({ indiceElement: 0 });
                }}
            >
              <Select
                  isClearable
                  noOptionsMessage={() => 'Aucune option'}
                  className={`${
                      this.state.indiceElement === 0
                          ? ''
                          : 'selection-sans-focus'
                  } selection ${propsOptions.multi ? 'selection-multi' : ''}`}
                  placeholder={''}
                  value={
                    items !== undefined && items !== null
                      ? propsOptions.multi
                        ? items.map(item => {
                          return {
                            value: item,
                            label: tableSelection.get(item)
                          };
                        })
                        : {
                            value: items,
                            label: tableSelection.get(items)
                          }
                      : null
                  }
                  onChange={elements => {
                    if (propsOnChange) {
                      if (elements instanceof Array) {
                        if (propsOptions.multi) {
                          propsOnChange(
                            elements.length > 0
                              ? elements.map(
                                element => element.value)
                              : null
                          );
                        } else {
                          propsOnChange(
                            elements.length > 0
                              ? elements[0].value
                              : null
                          );
                        }
                      } else {
                        if (elements) {
                          propsOnChange(elements
                            ? elements.value
                            : null);
                        } else {
                          propsOnChange(null);
                        }
                      }
                    }
                  }}
                  isMulti={propsOptions.multi}
                  options={propsOptions.items.map(item => {
                    return typeof item !== 'object'
                      ? {
                          value: item,
                          label: item
                        }
                      : {
                          value: item.valeur,
                          label: item.nom
                        };
                  })}
              />
            </View>
        );

      case 'motDePasse':
        return (
            <TextInput
                secureTextEntry={true}
                textContentType={'password'}
                style={[styles.contenu_filtre_valeur, propsStyle]}
                onChangeText={val => {
                  if (propsOnChange) {
                    propsOnChange(val || '');
                  }
                }}
                value={String(
                  propsValeur !== null && propsValeur !== undefined
                    ? propsValeur
                    : ''
                )}
            />
        );

      case 'texte':
      default:
        return (
            <TextInput
                style={[styles.contenu_filtre_valeur, propsStyle]}
                onChangeText={val => {
                  if (propsOnChange) {
                    propsOnChange(val || '');
                  }
                }}
                value={String(
                  propsValeur !== null && propsValeur !== undefined
                    ? propsValeur
                    : ''
                )}
            />
        );
    }
  }

  render () {
    const propsChange = val =>
      this.props.onChange(typeof val !== 'string' ? val : val || null);

    return (
        <View
            style={[
              styles.conteneur_filtre_contenu,
              this.props.focus ? styles.focus : null,
              this.state.focus ? styles.focus_immediat : null
            ]}
            onClick={() => {
              if (this.props.onClick) {
                this.props.onClick();
              }
            }}
            onFocus={() => {
              if (this.props.type !== 'date') {
                this.setState({ focus: true });
              }
            }}
            onBlur={() => {
              if (this.props.type !== 'date') {
                this.setState({ focus: false });
              }
            }}
        >
          <View
              style={[
                styles.contenu_filtre_nom,
                this.props.couleurNom
                  ? { backgroundColor: this.props.couleurNom }
                  : null,
                this.props.sousGroupe ? styles.taille_sous_groupe_nom : null
              ]}
          >
            <Text
                style={styles.contenu_filtre_nom_texte}>{this.props.nom}</Text>
          </View>
          <View
              style={[
                styles.conteneur_filtre_valeur,
                this.props.sousGroupe ? styles.taille_sous_groupe_item : null
              ]}
          >
            {this.renduParType(
              this.props.type,
              this.props.valeur,
              propsChange,
              this.props.style,
              this.props.options
            )}
          </View>
        </View>
    );
  }
}
