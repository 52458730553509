import { combineReducers, createStore } from 'redux';
import gestion from './reducers/gestion';
import timestamp from './reducers/timestamp';
import filtre from './reducers/filtre';
import layer from './reducers/layer';

export default createStore(
  combineReducers({
    carte: gestion('carte'),
    urgence: gestion('urgence'),
    timestamp,
    filtre,
    layer
  })
);
