import React, { Component } from 'react';
import { faExclamationTriangle, faLayerGroup, faRoute } from '@fortawesome/free-solid-svg-icons';
import Filtre from '../../elements/filtre/entete/rendu';
import styles, { raw } from './style';
import filtreHTB from '../../elements/filtreModele/htb';
import FiltreItem from '../../elements/filtreItem/rendu';
import Sticker from '../../elements/sticker/rendu';
import StickerBouton from '../../elements/stickerBouton/rendu';
import GestionnaireUrgence from '../gestionnaire/urgence/rendu';
import GestionnaireCarte from '../gestionnaire/trace/rendu';
import { connect } from 'react-redux';
import ModalLayers from '../carte/modal/layers/rendu';
import { MapboxStyleURL } from '../../../libs/utils';

class SideMenu extends Component {
  constructor (props) {
    super(props);

    this.state = {
      recherche: false,
      gestionCate: false,
      couche: false,
      gestionUrgence: false,
      boutonsSticker: [
        {
          icone: faRoute,
          titre: 'Tracé',
          fonction: () => {
            this.setState({ gestionCarte: true });
          }
        },
        {
          icone: faExclamationTriangle,
          titre: 'Priorité',
          fonction: () => {
            this.setState({ gestionUrgence: true });
          }
        },
        {
          icone: faLayerGroup,
          titre: 'Couche',
          fonction: () => {
            this.setState({ couche: true });
          }
        }
      ],
      filtre: this.initFiltre(),
      filtreTemp: this.initFiltre()
    };
  }

  initFiltre () {
    return {
      priorite: [],
      support: null,
      ouvrage: []
    };
  }

  render () {
    return [
      <Filtre
        recherche={this.state.recherche}
        onSearch={() => {

        }}
      >
        {[filtreHTB(this)].map(element => {
          if (element) {
            return [
              ...element.items.map(item => {
                return (
                  <FiltreItem
                    nom={item.nom}
                    type={item.type}
                    valeur={item.valeur.get()}
                    onChange={item.valeur.set}
                    options={item.options}
                  />
                );
              })
            ];
          }

          return null;
        })}
      </Filtre>,
      !this.state.gestionCarte && !this.state.gestionUrgence
        ? <Sticker style={raw.sticker_boutons} actif={!this.state.filtreActif}>
        {this.state.boutonsSticker.filter(
          boutonSticker => !boutonSticker.visible || boutonSticker.visible()
        ).map(boutonSticker => {
          return (
            <StickerBouton
              variant={boutonSticker.variant}
              titre={boutonSticker.titre}
              icone={boutonSticker.icone}
              onClick={() => {
                if (boutonSticker.fonction) {
                  boutonSticker.fonction(true);
                }
              }}
            />
          );
        })}
      </Sticker>
        : null,
      <GestionnaireCarte actif={this.state.gestionCarte} onClose={() => {
        this.setState({ gestionCarte: false });
      }}
                    style={styles.filtre} />,
      <GestionnaireUrgence actif={this.state.gestionUrgence} onClose={() => {
        this.setState({ gestionUrgence: false });
      }}
        style={styles.filtre} />,
      <ModalLayers isVisible={this.state.couche} actuel={MapboxStyleURL.Street} onHide={() => {
        this.setState({ couche: false });
      }} />
    ];
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
  return {
    updateFiltre: element =>
      dispatch({
        reference: 'FILTRE',
        type: 'UPDATE',
        filtre: element
      }),
    updateTimestampPoint: () =>
      dispatch({
        reference: 'TIMESTAMP',
        type: 'POINT'
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenu);
