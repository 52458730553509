import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  largeurRef,
  redimHauteur,
  redimLargeur
} from '../../../libs/utils';

export const raw = {
  lien: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    textDecorationLine: 'none'
  },
  conteneur: {
    position: 'relative'
  },
  entete_icone: {
    fontSize: redimHauteur(28),
    color: couleurPrincipalBlanc
  },
  bouton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  conteneur_entete: {
    position: 'absolute',
    left: redimLargeur(largeurRef * 0.25),
    height: '75%',
    width: redimLargeur(largeurRef * 0.5),
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: redimHauteur(8),
    borderBottomRightRadius: redimHauteur(8),
    borderColor: couleurBordurePrincipal,
    borderWidth: redimLargeur(1),
    borderTopWidth: 0
  },
  entete: {
    color: couleurPrincipalBlanc,
    fontWeight: 'bold',
    fontSize: redimHauteur(24),
    textTransform: 'uppercase'
  }
};

export default StyleSheet.create(raw);
