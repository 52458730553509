import { StyleSheet } from 'react-native-web';
import { redimLargeur } from '../../../libs/utils';

export const raw = {
  conteneur_filtre_statique: {
    zIndex: 2
  },
  conteneur_filtre_bouton: {
    width: redimLargeur(((128 + 48) * 3) / 2)
  }
};

export default StyleSheet.create(raw);
