import React, { Component } from 'react';
import Modal from '../../../../../../modal/rendu';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default class ModalPage extends Component {
  constructor (props) {
    super(props);
    this.state = this.props.actuel
      ? this.props.actuel
      : { pylone: null, priorite: null, ouvrage: null, programme: null };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.actuel !== this.props.actuel) {
      this.setState(
        nextProps.actuel
          ? nextProps.actuel
          : { pylone: null, priorite: null, ouvrage: null, programme: null }
      );
    }

    return true;
  }

  render () {
    const items = this.props.page && this.props.page.length > 0
      ? this.props.page[0].map((val, indice) => {
        return <MenuItem value={indice}>{val.valeur}</MenuItem>;
      })
      : null;

    return (
      <Modal
        onHide={this.props.onHide}
        validationDisabled={
          this.state.pylone === null ||
          this.state.priorite === null ||
          this.state.programme === null ||
          this.state.ouvrage === null
        }
        isVisible={this.props.isVisible}
        onValidate={() => {
          if (typeof this.props.onValidate === 'function') {
            const urgence = {
              ouvrage: this.state.ouvrage,
              priorite: this.state.priorite,
              pylone: this.state.pylone,
              programme: this.state.programme
            };

            this.setState(
              this.props.actuel
                ? this.props.actuel
                : { ouvrage: null, pylone: null, priorite: null, programme: null }
            );

            this.props.onValidate(urgence);
          }
        }}
        onCancel={this.props.onCancel}>
        <Modal.Entete>Ouvrage</Modal.Entete>
        <FormControl
          style={{ width: '100%' }}>
          <InputLabel id={'label-urgence-ouvrage'}>Selectionner une colonne</InputLabel>
          <Select
            labelId={'label-urgence-ouvrage'}
            autoWidth={true}
            value={this.state.ouvrage}
            onChange={event => {
              this.setState({ ouvrage: event.target.value });
            }}>
            {items}
          </Select>
        </FormControl>
        <Modal.Entete>Pylône</Modal.Entete>
        <FormControl
          style={{ width: '100%' }}>
          <InputLabel id={'label-urgence-pylone'}>Selectionner une colonne</InputLabel>
          <Select
            labelId={'label-urgence-pylone'}
            autoWidth={true}
            value={this.state.pylone}
            onChange={event => {
              this.setState({ pylone: event.target.value });
            }}>
            {items}
          </Select>
        </FormControl>
        <Modal.Entete>Programme</Modal.Entete>
        <FormControl
          style={{ width: '100%' }}>
          <InputLabel id={'label-urgence-programme'}>Selectionner une colonne</InputLabel>
          <Select
            labelId={'label-urgence-programme'}
            autoWidth={true}
            value={this.state.programme}
            onChange={event => {
              this.setState({ programme: event.target.value });
            }}>
            {items}
          </Select>
        </FormControl>
        <Modal.Entete>Priorité</Modal.Entete>
        <FormControl
          style={{ width: '100%' }}>
          <InputLabel id={'label-urgence-priorite'}>Selectionner une colonne</InputLabel>
          <Select
            labelId={'label-urgence-priorite'}
            autoWidth={true}
            value={this.state.priorite}
            onChange={event => {
              this.setState({ priorite: event.target.value });
            }}>
            {items}
          </Select>
        </FormControl>
      </Modal>
    );
  }
}
