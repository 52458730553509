import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurPrincipalGris,
  redimHauteur,
  redimLargeur
} from '../../../libs/utils';

export const raw = {
  conteneur_filtre_contenu: {
    flexDirection: 'row',
    width: '100%',
    minHeight: redimHauteur(56),
    flexShrink: 1
  },
  taille_sous_groupe_item: {
    width: '65%'
  },
  taille_sous_groupe_nom: {
    width: '35%'
  },
  contenu_filtre_nom: {
    width: '27.5%',
    paddingHorizontal: redimLargeur(8),
    paddingVertical: redimHauteur(12),
    minHeight: redimHauteur(56),
    justifyContent: 'center',
    backgroundColor: couleurPrincipalGris,
    alignItems: 'center',
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: redimLargeur(1),
    borderRightColor: couleurBordurePrincipal,
    borderRightWidth: redimLargeur(1)
  },
  contenu_filtre_nom_texte: {
    fontSize: redimHauteur(20),
    color: couleurPrincipalBlanc,
    width: '100%',
    fontWeight: 'bold'
  },
  conteneur_filtre_valeur: {
    width: '72.5%',
    height: '100%'
  },
  contenu_filtre_valeur: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    paddingVertical: redimHauteur(12),
    backgroundColor: couleurPrincipalBlanc,
    alignItems: 'center',
    paddingHorizontal: redimLargeur(16),
    fontSize: redimHauteur(18),
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: redimLargeur(1)
  },
  focus: {
    zIndex: 1
  },
  focus_immediat: {
    zIndex: 2
  },
  date_picker: {
    height: '100%',
    width: '100%',
    borderWidth: 0,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    lineHeight: 0,
    boxShadow: 'none'
  },
  date_picker_texte: {
    fontSize: redimHauteur(18),
    display: 'flex',
    paddingHorizontal: redimLargeur(8)
  },

  date_picker_single_texte: {
    paddingHorizontal: redimLargeur(16)
  },
  contenu_filtre_sous_nom: {
    width: '30%',
    borderLeftWidth: redimLargeur(1),
    borderLeftColor: couleurBordurePrincipal,
    borderBottomWidth: 0
  },
  contenu_filtre_ligne: {
    fontSize: redimHauteur(18),
    flexDirection: 'row',
    width: '100%',
    minHeight: redimHauteur(56),
    flexGrow: 1,
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: redimLargeur(1)
  },
  contenu_filtre_lignes: {
    height: '100%',
    width: '100%'
  },
  contenu_filtre_selection: {
    fontSize: redimHauteur(18),
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    minHeight: redimHauteur(56),
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: redimLargeur(1)
  },
  taille_max: {
    height: '100%',
    width: '100%'
  },
  taille_position_dms: {
    height: '100%',
    width: `${(100 - 30) * 0.25}%`,
    borderRightWidth: redimLargeur(1),
    borderRightColor: couleurBordurePrincipal,
    borderBottomWidth: 0,
    paddingHorizontal: redimLargeur(8)
  },
  taille_position_dd: {
    height: '100%',
    width: `${100 - 30}%`,
    borderBottomWidth: 0,
    paddingHorizontal: redimLargeur(8)
  },
  taille_position_rayon: {
    height: '100%',
    width: `${(100 - 30) * 0.75}%`,
    borderBottomWidth: 0,
    paddingHorizontal: redimLargeur(8)
  },
  taille_position_rayon_suffixe: {
    height: '100%',
    width: `${(100 - 30) * 0.25}%`,
    borderRightWidth: redimLargeur(1),
    borderRightColor: couleurBordurePrincipal,
    borderBottomWidth: 0,
    paddingHorizontal: redimLargeur(8)
  },
  taille_position_rayon_suffixe_texte: {
    width: 'auto'
  }
};

export default StyleSheet.create(raw);
