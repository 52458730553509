import React, { Component, createRef } from 'react';
import * as utm from 'utm';
import ReactMapboxGL, { GeoJSONLayer, Popup } from 'react-mapbox-gl';
import { ActivityIndicator, View } from 'react-native-web';
import { connect } from 'react-redux';
import styles, { raw } from './style';
import * as SecureStorage from '../../../libs/secure-storage';
import Bounds from '../../../libs/bounds';
import { couleurPrincipalBleu, MapboxStyleURL } from '../../../libs/utils';
import uniqid from 'uniqid';

const MapView = ReactMapboxGL({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  pitchWithRotate: false,
  dragRotate: false,
  touchZoomRotate: false
});

class Carte extends Component {
  mapView;
  bounds;
  timestampPoint;
  timestampBBOX;

  urgences;
  lignes;
  lignesLayer;
  pointsPylones;
  pointsPylonesLayer;
  tablePointsPylones;
  pointsPortiques;
  pointsPortiquesLayer;
  tablePointsPortiques;

  zoomCarte;
  centreCarte;
  idActuel;
  carteCharger;

  constructor (props) {
    super(props);

    this.zoomCarte = undefined;
    this.centreCarte = undefined;
    this.timestampBBOX = 0;
    this.timestampPoint = 0;
    this.mapView = createRef();
    this.bounds = new Bounds();

    this.lignesLayer = null;
    this.pointsPortiquesLayer = null;
    this.pointsPylonesLayer = null;
    this.urgences = null;
    this.lignes = null;
    this.pointsPylones = null;
    this.tablePointsPylones = null;
    this.pointsPortiques = null;
    this.tablePointsPortiques = null;

    this.idActuel = null;

    this.carteCharger = false;

    this.state = {
      charger: false,
      autorisationPositionUtilisateur: false,
      liste: [],
      bounds: new Bounds(),
      element: null
    };
  }

  componentDidMount () {
    SecureStorage.get('carte_liste', [])
      .then(listeActuel => {
        return Promise.all(
          listeActuel.map(async nom => {
            try {
              const donnees = await SecureStorage.get(`carte_${nom}`);
              this.props.addCarte(donnees);
            } catch (e) {
              console.error(e);
            }
          })
        );
      })
      .then(() => {
        SecureStorage.get('urgence_liste', []).then(listeActuel => {
          Promise.all(
            listeActuel.map(async nom => {
              try {
                const donnees = await SecureStorage.get(`urgence_${nom}`);
                this.props.addUrgence(donnees);
              } catch (e) {
                console.error(e);
              }
            })
          ).then(() => {
            this.setState({ charger: true });
          });
        });
      });

    SecureStorage.get('styleURL', MapboxStyleURL.Street).then(styleURL => {
      this.props.updateLayer(styleURL);
    });
  }

  getDonneesUrgences () {
    const donnees = new Map();

    donnees.set(null, new Map());

    for (const nom of this.props.urgence.liste) {
      donnees.set(nom, new Map());

      for (const urgence of this.props.urgence.donnees[nom].urgences) {
        if (!donnees.get(nom).has(urgence.ouvrage)) {
          donnees.get(nom).set(urgence.ouvrage, new Set());
        }

        donnees
          .get(nom)
          .get(urgence.ouvrage)
          .add(urgence.indicePylone);

        if (!donnees.get(null).has(urgence.ouvrage)) {
          donnees.get(null).set(urgence.ouvrage, new Map());
        }

        donnees
          .get(null)
          .get(urgence.ouvrage)
          .set(urgence.indicePylone, { nom, programme: urgence.programme });
      }
    }

    return donnees;
  }

  getDonneesCarte () {
    const listeDonnees = [];
    const bounds = new Bounds();

    for (const nom of this.props.carte.liste) {
      const donnees = this.props.carte.donnees[nom];
      if (donnees) {
        listeDonnees.push(donnees);
        const positionUtm = utm.fromLatLon(
          donnees.lieu.bbox[1],
          donnees.lieu.bbox[0]
        );
        const min = utm.toLatLon(
          donnees.bounds.min.x,
          donnees.bounds.min.y,
          positionUtm.zoneNum,
          null,
          true
        );
        const max = utm.toLatLon(
          donnees.bounds.max.x,
          donnees.bounds.max.y,
          positionUtm.zoneNum,
          null,
          true
        );

        bounds.encapsulate({ x: min.longitude, y: min.latitude });
        bounds.encapsulate({ x: max.longitude, y: max.latitude });
      }
    }

    if (!Bounds.equals(bounds, this.bounds)) {
      this.bounds = bounds;
      this.setState({ bounds });
    }

    return listeDonnees;
  }

  verifLigne (donnees) {
    return (
      this.props.carte.donnees[donnees.nom] &&
      this.props.carte.donnees[donnees.nom].lignes.length > 0 &&
      this.props.carte.donnees[donnees.nom].timestamp === donnees.timestamp
    );
  }

  getInformationPoint (element) {
    if (element.point.indicePylone !== null) {
      const urgence =
        element && this.urgences && this.urgences.has(element.donnees.nom)
          ? this.urgences
            .get(element.donnees.nom)
            .get(element.point.indicePylone) || {}
          : {};

      return <table>
        <tr style={raw.informationContenu}>
          <th style={raw.informationTitre}>Priorité</th>
          <td style={raw.informationValeur}>
            {element ? urgence.nom || 'AUCUNE' : ''}
          </td>
        </tr>
        <tr style={{ ...raw.informationContenu, ...raw.informationContenuBordure }}>
          <th style={raw.informationTitre}>Ouvrage</th>
          <td style={raw.informationValeur}>
            {element ? element.donnees.nom : ''}
          </td>
        </tr>
        <tr style={{ ...raw.informationContenu, ...raw.informationContenuBordure }}>
          <th style={raw.informationTitre}>Support</th>
          <td style={raw.informationValeur}>
            {element ? element.point.nom : ''}
          </td>
        </tr>
        {urgence.nom
          ? (
          <tr style={{ ...raw.informationContenu, ...raw.informationContenuBordure }}>
            <th style={raw.informationTitre}>Programme</th>
            <td style={raw.informationValeur}>
              {element ? urgence.programme || 'AUCUN' : ''}
            </td>
          </tr>
            )
          : null}
      </table>;
    } else {
      return <table>
        <tr style={raw.informationContenu}>
          <th style={raw.informationTitre}>Ouvrage</th>
          <td style={raw.informationValeur}>
            {element ? element.donnees.nom : ''}
          </td>
        </tr>
        <tr style={{ ...raw.informationContenu, ...raw.informationContenuBordure }}>
          <th style={raw.informationTitre}>Portique</th>
          <td style={raw.informationValeur}>
            {element
              ? (element.point.nom || '').replace(/^Portique /i, '')
              : ''}
          </td>
        </tr>
      </table>;
    }
  }

  verifPoint (element) {
    if (element) {
      const urgence =
        this.urgences && this.urgences.has(element.donnees.nom)
          ? this.urgences
            .get(element.donnees.nom)
            .get(element.point.indicePylone) || {}
          : {};

      return (
        this.props.carte.donnees[element.donnees.nom] &&
        this.props.carte.donnees[element.donnees.nom].points.length > 0 &&
        this.props.carte.donnees[element.donnees.nom].timestamp ===
          element.donnees.timestamp &&
        (element.point.indicePylone === null ||
          ((!this.props.filtre.priorite ||
            this.props.filtre.priorite.includes(urgence.nom || null)) &&
            (!this.props.filtre.ouvrage ||
              this.props.filtre.ouvrage.includes(element.donnees.nom)) &&
            (!this.props.filtre.programme ||
              this.props.filtre.programme.includes(
                urgence.programme || null
              )) &&
            (!this.props.filtre.support ||
              element.point.nom
                .toLowerCase()
                .includes(this.props.filtre.support.toLowerCase()))))
      );
    }

    return false;
  }

  estCharger () {
    return this.props.layer.styleURL && this.state.charger;
  }

  render () {
    if (!this.estCharger()) {
      return (
        <View style={[styles.conteneur, styles.conteneurChargement]}>
          <ActivityIndicator size="large" color={couleurPrincipalBleu} />
        </View>
      );
    }

    if (this.timestampPoint < this.props.timestamp.point) {
      this.timestampPoint = this.props.timestamp.point;

      if (this.mapView.current) {
        this.mapView.current.getCanvas().style.cursor = 'wait';
      }

      const listeDonneesCarte = this.getDonneesCarte();
      const tableDonneesUrgence = this.getDonneesUrgences();
      this.lignes = [];
      this.pointsPylones = new Map();
      this.tablePointsPylones = new Map();
      this.pointsPortiques = [];
      this.tablePointsPortiques = new Map();

      this.idActuel = uniqid();

      this.urgences = tableDonneesUrgence.get(null);
      const listePriorite = [
        null,
        ...this.props.urgence.liste.sort().reverse()
      ];
      for (const nomPriorite of listePriorite) {
        this.pointsPylones.set(nomPriorite, []);
        this.tablePointsPylones.set(nomPriorite, new Map());
      }

      for (const donnees of listeDonneesCarte) {
        const positionUtm = utm.fromLatLon(
          donnees.lieu.bbox[1],
          donnees.lieu.bbox[0]
        );

        if (this.verifLigne(donnees)) {
          for (const ligne of donnees.lignes) {
            this.lignes.push({
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: ligne.points.map(point => {
                  const pointUtm = utm.toLatLon(
                    point.x,
                    point.y,
                    positionUtm.zoneNum,
                    null,
                    true
                  );

                  return [pointUtm.longitude, pointUtm.latitude];
                })
              }
            });
          }
        }

        for (const point of donnees.points) {
          const element = { donnees, point };
          if (this.verifPoint(element)) {
            const pointUtm = utm.toLatLon(
              point.x,
              point.y,
              positionUtm.zoneNum,
              null,
              true
            );
            const id = uniqid();

            element.coordinates = [pointUtm.longitude, pointUtm.latitude];

            if (point.indicePylone === null) {
              this.tablePointsPortiques.set(id, element);

              this.pointsPortiques.push({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: element.coordinates
                },
                properties: {
                  id
                }
              });
            } else {
              const urgence = this.urgences.has(donnees.nom)
                ? this.urgences.get(donnees.nom).get(point.indicePylone) || {}
                : {};
              const nom = urgence.nom || null;

              this.tablePointsPylones.get(nom).set(id, element);

              this.pointsPylones.get(nom).push({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: element.coordinates
                },
                properties: {
                  id
                }
              });
            }
          }
        }
      }

      this.lignesLayer = [
        <GeoJSONLayer
          data={{
            type: 'FeatureCollection',
            features: this.lignes
          }}
          id={`remplissage_ligne_${this.idActuel}`}
          linePaint={{
            'line-color': '#343a40',
            'line-width': 4
          }}
        />,
        <GeoJSONLayer
          data={{
            type: 'FeatureCollection',
            features: this.lignes
          }}
          id={`contour_ligne_${this.idActuel}`}
          linePaint={{
            'line-color': '#ffc107',
            'line-width': 3
          }}
        />
      ];

      const onSelectPoint = (event, nomPropriete) => {
        const tablePoints = typeof nomPropriete === 'undefined' ? this.tablePointsPortiques : this.tablePointsPylones.get(nomPropriete);
        const element = tablePoints.get(event.features[0].properties.id);
        const donnees = Object.assign({}, element.donnees);
        delete donnees.points;
        delete donnees.lignes;

        donnees.lieu = {
          bbox: donnees.lieu.bbox
        };

        this.mapView.current.getCanvas().style.cursor = 'pointer';

        this.setState({
          elementSelected: {
            id: event.features[0].properties.id,
            point: element.point,
            donnees
          }
        });
      };

      this.pointsPortiquesLayer = (
        <GeoJSONLayer
          circleOnMouseEnter={event => onSelectPoint(event)}
          circleOnMouseMove={event => {
            if (event.features && event.features.length !== 0 && (!this.state.elementSelected || event.features[0].properties.id !== this.state.elementSelected.id)) {
              onSelectPoint(event);
            }
          }}
          circleOnMouseLeave={() => {
            this.mapView.current.getCanvas().style.cursor = 'default';

            this.setState({
              elementSelected: null
            });
          }}
          data={{
            type: 'FeatureCollection',
            features: this.pointsPortiques
          }}
          id={`point_portique_${this.idActuel}`}
          circlePaint={{
            'circle-color': '#ffdf80',
            'circle-radius': 6,
            'circle-stroke-width': 3,
            'circle-stroke-color': '#343a40'
          }}
        />
      );

      this.pointsPylonesLayer = Array.from(this.pointsPylones).map(elementPointsPylones => {
        const nomPriorite = elementPointsPylones[0];
        const points = elementPointsPylones[1];

        return (
          <GeoJSONLayer
            circleOnMouseEnter={event => onSelectPoint(event, nomPriorite)}
            circleOnMouseMove={event => {
              if (event.features && event.features.length !== 0 && (!this.state.elementSelected || event.features[0].properties.id !== this.state.elementSelected.id)) {
                onSelectPoint(event, nomPriorite);
              }
            }}
            circleOnMouseLeave={() => {
              this.mapView.current.getCanvas().style.cursor = 'default';

              this.setState({
                elementSelected: null
              });
            }}
            data={{
              type: 'FeatureCollection',
              features: points
            }}
            id={`point_pylone_${nomPriorite || ''}_${this.idActuel}`}
            circlePaint={{
              'circle-color': nomPriorite
                ? this.props.urgence.donnees[nomPriorite].couleur
                : '#6c757d',
              'circle-radius': 3,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#343a40'
            }}
          />
        );
      });
    }

    const positionUtmActuel = this.state.elementSelected
      ? utm.fromLatLon(
        this.state.elementSelected.donnees.lieu.bbox[1],
        this.state.elementSelected.donnees.lieu.bbox[0]
      )
      : null;

    const pointUtmActuel = this.state.elementSelected
      ? utm.toLatLon(
        this.state.elementSelected.point.x,
        this.state.elementSelected.point.y,
        positionUtmActuel.zoneNum,
        null,
        true
      )
      : null;

    let fitBounds;

    if (this.timestampBBOX < this.props.timestamp.bbox) {
      this.timestampBBOX = this.props.timestamp.bbox;
      if (this.bounds.isValide()) {
        fitBounds = [
          [this.bounds.min.x, this.bounds.min.y],
          [this.bounds.max.x, this.bounds.max.y]
        ];

        if (this.centreCarte || this.zoomCarte) {
          this.centreCarte = undefined;
          this.zoomCarte = undefined;
          this.timestampBBOX = 0;

          this.setState({ __mapboxWorkaround: Date.now() });
        }
      } else {
        this.centreCarte = [0, 0];
        this.zoomCarte = [1.9];
      }
    }

    const popupOffsetScale = !this.state.elementSelected || this.state.elementSelected.point.indicePylone !== null ? 1 : 2;

    return (
      <View style={styles.conteneur}>
        {[
          <MapView
            fitBounds={fitBounds}
            fitBoundsOptions={{
              padding: 32,
              linear: true
            }}
            center={this.centreCarte}
            zoom={this.zoomCarte}
            onStyleLoad={map => {
              this.mapView.current = map;
              map.getCanvas().style.cursor = 'wait';

              map.on('sourcedata', event => {
                if (
                  event.isSourceLoaded &&
                  map.getCanvas().style.cursor === 'wait' &&
                  !event.sourceDataType
                ) {
                  map.getCanvas().style.cursor = 'default';
                }
              });
            }}
            onRender={event => {
              if (!this.carteCharger) {
                this.carteCharger = true;
                event._canvas.style.cursor = 'wait';
              }
            }}
            onStyleDataLoading={event => {
              event._canvas.style.cursor = 'wait';
            }}
            style={this.props.layer.styleURL}
            containerStyle={raw.conteneur}>
            {this.lignesLayer}
            {this.pointsPortiquesLayer}
            <GeoJSONLayer
              data={{
                type: 'FeatureCollection',
                features:
                  this.verifPoint(this.state.elementSelected) &&
                  this.state.elementSelected.point.indicePylone === null
                    ? [
                        {
                          type: 'Feature',
                          geometry: {
                            type: 'Point',
                            coordinates: [
                              pointUtmActuel.longitude,
                              pointUtmActuel.latitude
                            ]
                          }
                        }
                      ]
                    : []
              }}
              id={`selection_point_portique_${this.idActuel}`}
              circlePaint={{
                'circle-color': 'white',
                'circle-radius': 6,
                'circle-stroke-width': 3,
                'circle-stroke-color': '#343a40'
              }}
            />
            {this.pointsPylonesLayer}
            {this.verifPoint(this.state.elementSelected)
              ? [
                  this.state.elementSelected.point.indicePylone !== null
                    ? <GeoJSONLayer
                    data={{
                      type: 'Feature',
                      geometry: {
                        type: 'Point',
                        coordinates: [
                          pointUtmActuel.longitude,
                          pointUtmActuel.latitude
                        ]
                      }
                    }}
                    id={`selection_point_pylone_${this.idActuel}`}
                    circlePaint={{
                      'circle-color': 'white',
                      'circle-radius': 3,
                      'circle-stroke-width': 1,
                      'circle-stroke-color': '#343a40'
                    }}
                  />
                    : null,
                <Popup
                  coordinates={[
                    pointUtmActuel.longitude,
                    pointUtmActuel.latitude
                  ]}
                  offset={{
                    'bottom-left': [2.5 * popupOffsetScale, -2.5 * popupOffsetScale],
                    bottom: [0, -5 * popupOffsetScale],
                    'bottom-right': [-2.5 * popupOffsetScale, -2.5 * popupOffsetScale],
                    'top-left': [2.5 * popupOffsetScale, 2.5 * popupOffsetScale],
                    top: [0, 5 * popupOffsetScale],
                    'top-right': [-2.5 * popupOffsetScale, 2.5 * popupOffsetScale],
                    right: [-5 * popupOffsetScale, 0],
                    left: [5 * popupOffsetScale, 0]
                  }}>
                  {this.getInformationPoint(this.state.elementSelected)}
                </Popup>
                ]
              : null}
          </MapView>
        ]}
      </View>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
  return {
    addCarte: element =>
      dispatch({
        reference: 'CARTE',
        type: 'ADD',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    addUrgence: element =>
      dispatch({
        reference: 'URGENCE',
        type: 'ADD',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    updateLayer: element =>
      dispatch({
        reference: 'LAYER',
        type: 'UPDATE',
        styleURL: element
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Carte);
