export const largeurRef = 1920;
export const hauteurRef = 1080;
export const couleurPrincipalVert = '#74d14c';
export const couleurPrincipalBleu = '#4a98ff';
export const couleurSecondaireBleu = '#0990db';
export const couleurPrincipalGris = '#41494f';
export const couleurPrincipalBlanc = '#fff';
export const couleurSecondaireBlanc = '#eee';
export const couleurTertiaireBlanc = '#ddd';
export const couleurBordurePrincipal = '#888';

export const MapboxStyleURL = {
  Street: 'mapbox://styles/mapbox/streets-v11',
  Dark: 'mapbox://styles/mapbox/dark-v10',
  Light: 'mapbox://styles/mapbox/light-v10',
  Outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  Satellite: 'mapbox://styles/mapbox/satellite-v9',
  SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
  TrafficDay: 'mapbox://styles/mapbox/navigation-preview-day-v4',
  TrafficNight: 'mapbox://styles/mapbox/navigation-preview-night-v4'
};

export function redimLargeur (largeur) {
  return `${(largeur / largeurRef) * 100}vw`;
}

export function redimHauteur (hauteur) {
  return `${(hauteur / hauteurRef) * 100}vh`;
}

export function premiereColonne (feuille, ligne = 0) {
  let val;

  if (ligne === 0) {
    val = 0;

    for (let i = premiereLigne(feuille); i <= derniereLigne(feuille); i++) {
      if (val === 0) {
        val = premiereColonne(feuille, i);
      } else {
        val = Math.min(premiereColonne(feuille, i), val);
      }
    }
  } else if (feuille.getRow(ligne).values.length > 0) {
    val = 1;
    while (
      val < feuille.getRow(ligne).values.length &&
      (feuille.getRow(ligne).getCell(val).value === null ||
        feuille.getRow(ligne).getCell(val).value === undefined)
    ) {
      val++;
    }
  }

  return val;
}

export function derniereColonne (feuille, ligne = 0) {
  let val;

  if (ligne === 0) {
    val = 0;

    for (let i = premiereLigne(feuille); i <= derniereLigne(feuille); i++) {
      val = Math.max(val, derniereColonne(feuille, i));
    }
  } else {
    val = feuille.getRow(ligne).values.length;
    while (
      val > 0 &&
      (feuille.getRow(ligne).getCell(val).value === null ||
        feuille.getRow(ligne).getCell(val).value === undefined)
    ) {
      val--;
    }
  }

  return val;
}

export function premiereLigne (feuille, colonne = 0) {
  let val = 1;

  if (colonne === 0) {
    while (!feuille.getRow(val).hasValues && val < feuille.rowCount) {
      val++;
    }
  } else {
    while (
      val < feuille.rowCount &&
      (feuille.getRow(val).getCell(colonne).value === null ||
        feuille.getRow(val).getCell(colonne).value === undefined)
    ) {
      val++;
    }
  }

  return val;
}

export function derniereLigne (feuille, colonne = 0) {
  let val = feuille.rowCount;

  if (colonne === 0) {
    while (!feuille.getRow(val).hasValues && val > 0) {
      val--;
    }
  } else {
    while (
      val > 0 &&
      (feuille.getRow(val).getCell(colonne).value === null ||
        feuille.getRow(val).getCell(colonne).value === undefined)
    ) {
      val--;
    }
  }

  return val;
}
