export default function filtreHTB (page) {
  const urgences = [].concat(...Object.values(page.props.urgence.donnees).map(donnee => donnee.urgences));
  const programmes = new Set(urgences.map(urgence => urgence.programme));

  return {
    items: [
      {
        nom: 'Priorité',
        type: 'selection',
        valeur: {
          set: val => {
            page.props.updateFiltre({ priorite: val });
            page.props.updateTimestampPoint();
          },
          get: () => page.props.filtre.priorite
        },
        options: {
          multi: true,
          items: [null, ...page.props.urgence.liste].map(item => ({ valeur: item, nom: item || 'AUCUNE' }))
        }
      },
      {
        nom: 'Ouvrage',
        type: 'selection',
        valeur: {
          set: val => {
            page.props.updateFiltre({ ouvrage: val });
            page.props.updateTimestampPoint();
          },
          get: () => page.props.filtre.ouvrage
        },
        options: {
          multi: true,
          items: page.props.carte.liste
        }
      },
      {
        nom: 'Support',
        type: 'texte',
        valeur: {
          set: val => {
            page.props.updateFiltre({ support: val });
            page.props.updateTimestampPoint();
          },
          get: () => page.props.filtre.support
        }
      },
      {
        nom: 'Programme',
        type: 'selection',
        valeur: {
          set: val => {
            page.props.updateFiltre({ programme: val });
            page.props.updateTimestampPoint();
          },
          get: () => page.props.filtre.programme
        },
        options: {
          multi: true,
          items: [null, ...programmes].map(item => ({ valeur: item, nom: item || 'AUCUN' }))
        }
      }
    ]
  };
}
