import { StyleSheet } from 'react-native-web';
import { redimHauteur } from '../../../../../libs/utils';

export const raw = {
  texte: {
    fontSize: redimHauteur(18)
  }
};

export default StyleSheet.create(raw);
