import React, { Component } from 'react';
import { ActivityIndicator, ScrollView, Text, View } from 'react-native-web';
import styles, { raw } from '../../../filtre/dropdown/style';
import stylesNative, { raw as rawNative } from '../../../modification/style';
import { Button } from 'react-bootstrap';
import { couleurPrincipalBlanc } from '../../../../../libs/utils';
import ReactFileInput from '../../../../fichier/ReactFileInput';

export default class Modification extends Component {
  constructor (props) {
    super(props);
    this.state = { indiceFocus: -1 };
  }

  render () {
    if (this.props.actif) {
      return (
          <View style={[styles.conteneur_filtre, this.props.style]}>
            <View style={stylesNative.conteneur_filtre_statique}>
              {React.Children.map(
                this.props.contenuStatique,
                (enfant, indice) => {
                  if (enfant) {
                    return React.cloneElement(enfant, {
                      focus: this.state.indiceFocus === indice,
                      onClick: () => {
                        this.setState({ indiceFocus: indice });
                      }
                    });
                  }

                  return null;
                }
              )}
            </View>
            <ScrollView horizontal={false} vertical={true}>
              {React.Children.map(this.props.children, (enfant, indice) => {
                if (enfant && (!enfant.props || !enfant.props.statique)) {
                  return React.cloneElement(enfant, {
                    focus: this.state.indiceFocus === indice,
                    onClick: () => {
                      this.setState({ indiceFocus: indice });
                    }
                  });
                }

                return null;
              })}
            </ScrollView>
            <View style={styles.conteneur_filtre_boutons}>
              <ReactFileInput
                accept={this.props.accept}
              onLoad={this.props.onLoad}>
              <Button
                  style={{
                    ...raw.conteneur_filtre_bouton,
                    ...rawNative.conteneur_filtre_bouton
                  }}
              >
                <ActivityIndicator
                    style={[
                      styles.filtre_icone,
                      !this.props.chargement ? styles.cacher : null
                    ]}
                    color={couleurPrincipalBlanc}
                />
                <Text style={styles.conteneur_filtre_bouton_texte}>
                  {this.props.texteBoutonModification || 'Importer'}
                </Text>
              </Button>
              </ReactFileInput>
              <Button
                  style={{
                    ...raw.conteneur_filtre_bouton,
                    ...rawNative.conteneur_filtre_bouton
                  }}
                  onClick={this.props.onClose}
              >
                <Text
                    style={styles.conteneur_filtre_bouton_texte}>Fermer</Text>
              </Button>
            </View>
          </View>
      );
    }

    return null;
  }
}
