import React, { Component } from 'react';
import styles from './style';
import { View } from 'react-native-web';

export default class Valeur extends Component {
  render () {
    return (
      <View style={[styles.conteneur, this.props.style]} onClick={this.props.onClick}>
        {this.props.children}
      </View>
    );
  }
}
