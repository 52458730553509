import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurPrincipalBleu,
  couleurSecondaireBlanc,
  couleurTertiaireBlanc,
  hauteurRef,
  redimHauteur
} from '../../../../../libs/utils';

export const raw = {
  conteneur: {
    height: redimHauteur(hauteurRef * 0.5),
    width: '100%',
    backgroundColor: couleurPrincipalBlanc
  },
  conteneurChargement: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  bouton: {
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: couleurPrincipalBleu
  },
  boutonTexte: {
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold'
  },
  boutonIcone: {
    width: '50%',
    height: '50%'
  },
  sousBouton: {
    height: 64,
    width: 64,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: couleurBordurePrincipal,
    borderLeftColor: couleurBordurePrincipal,
    borderRightColor: couleurBordurePrincipal,
    borderBottomWidth: 0.25,
    borderLeftWidth: 0.25,
    borderRightWidth: 0.25
  },
  sousBoutonConteneur: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    paddingVertical: 8
  },
  sousBoutonTexte: {
    color: 'white',
    fontSize: 10,
    textAlign: 'center'
  },
  swipeViewListe: {
    height: 64,
    backgroundColor: couleurPrincipalBlanc,
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: 0.25,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  swipedViewSousListe: {
    height: 64,
    flexDirection: 'row'
  },
  swipeViewActif: {
    backgroundColor: couleurPrincipalBlanc
  },
  swipeViewInactif: {
    backgroundColor: couleurTertiaireBlanc
  },
  listeBoutons: {
    width: '100%',
    flexDirection: 'row'
  },
  swipeViewCouleur: {
    height: 64,
    width: 64,
    borderBottomWidth: 0.25,
    borderLeftWidth: 0.25,
    borderBottomColor: couleurBordurePrincipal,
    borderLeftColor: couleurBordurePrincipal
  },
  swipeView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 64,
    width: '100%',
    alignItems: 'center',
    backgroundColor: couleurPrincipalBlanc,
    borderBottomColor: couleurBordurePrincipal,
    borderLeftColor: couleurBordurePrincipal,
    borderRightColor: couleurBordurePrincipal,
    borderBottomWidth: 0.25,
    borderLeftWidth: 0.25,
    borderRightWidth: 0.25
  },
  swipeViewTexte: {
    color: couleurBordurePrincipal,
    fontSize: 18
  },
  liste: {
    flex: 1,
    backgroundColor: couleurSecondaireBlanc
  },
  conteneurHaut: {
    position: 'absolute',
    top: 0,
    width: '100%',
    flexDirection: 'row-reverse'
  },
  rechercheConteneur: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0
  },
  recherche: {
    width: '100%',
    backgroundColor: couleurSecondaireBlanc,
    borderWidth: 0.25,
    borderColor: couleurBordurePrincipal
  },
  suppressionRecherche: {
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center'
  },
  rechercheTexte: {
    backgroundColor: couleurPrincipalBlanc,
    borderColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    width: '100%'
  }
};

export default StyleSheet.create(raw);
