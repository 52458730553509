import { StyleSheet } from 'react-native-web';
import { couleurPrincipalBlanc, redimHauteur } from '../../libs/utils';

export const raw = {
  conteneur: {
    flex: 1
  },
  conteneurTransparent: {
    flex: 1,
    backgroundColor: 'transparent'
  },
  listeBoutons: {
    width: '100%',
    flexDirection: 'row'
  },
  boutonTexte: {
    fontSize: redimHauteur(18),
    color: couleurPrincipalBlanc,
    lineHeight: 0,
    textTransform: 'uppercase'
  },
  texte: {
    fontSize: redimHauteur(18),
    lineHeight: 0,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  bouton: {
    height: redimHauteur(56),
    width: '100%',
    padding: 0,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: redimHauteur(1),
    borderColor: couleurPrincipalBlanc
  }
};

export default StyleSheet.create(raw);
