import { StyleSheet } from 'react-native-web';
import { hauteurRef, largeurRef, redimHauteur, redimLargeur } from './libs/utils';

export const raw = {
  conteneur: {
    width: redimLargeur(largeurRef),
    height: redimHauteur(hauteurRef),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
    overflow: 'hidden'
  }
};

export default StyleSheet.create(raw);
