import React, { Component } from 'react';
import Modal from '../../../../modal/rendu';
import styles, { raw } from './style';
import { View } from 'react-native-web';
import { couleurPrincipalBlanc, MapboxStyleURL } from '../../../../../libs/utils';
import ReactMapboxGL from 'react-mapbox-gl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import throttle from 'lodash.throttle';

const MapView = ReactMapboxGL({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  interactive: false
});

const filterOptions = createFilterOptions({
  startAfter: 3
});

export default class ModalLieu extends Component {
  fetchData;
  mapbox;
  constructor (props) {
    super(props);
    this.fetchData = throttle(valeur => {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          valeur
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
      )
        .then(response => response.json())
        .then(response => {
          this.setState({ data: response.features || [] });
        });
    }, 200);
    this.state = {
      data: [this.props.actuel].filter(item => item),
      chargement: false,
      actuel: this.props.actuel,
      filtreInformation: this.props.actuel ? this.props.actuel.place_name : null
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.actuel !== this.props.actuel) {
      this.setState({
        chargement: false,
        data: [nextProps.actuel].filter(item => item),
        actuel: nextProps.actuel,
        filtreInformation: nextProps.actuel ? nextProps.actuel.place_name : null
      });
    }

    return true;
  }

  render () {
    let fitBounds;
    let zoom;
    let centre;

    if (this.state.actuel) {
      fitBounds = [[this.state.actuel.bbox[0], this.state.actuel.bbox[1]],
        [this.state.actuel.bbox[2], this.state.actuel.bbox[3]]];
    } else {
      centre = [0, 0];
      zoom = [1.9];
    }

    return (
      <Modal
        onHide={this.props.onHide}
        isVisible={this.props.isVisible}
        validationDisabled={!this.state.actuel}
        titre={'Lieu'}
        onValidate={() => {
          if (typeof this.props.onValidate === 'function') {
            const actuel = this.state.actuel;
            this.setState({ actuel: this.props.actuel });
            this.props.onValidate(actuel);
          }
        }}
        onCancel={this.props.onCancel}>
        <View style={styles.conteneur}>
          <MapView
            onStyleLoad={map => map.resize()}
            style={MapboxStyleURL.Street}
            containerStyle={raw.conteneur}
            fitBoundsOptions={{
              padding: 32,
              linear: true
            }}
                               center={centre}
                               zoom={zoom}
                               fitBounds={fitBounds} />
          <View style={styles.conteneurHaut}>
              <Autocomplete
                loading={this.state.chargement}
                loadingText={'Chargement en cours...'}
                filterOptions={filterOptions}
                autoHighlight={true}
                blurOnSelect={true}
                fullWidth={true}
                freeSolo={true}
                inputValue={this.state.filtreInformation || ''}
                renderInput={(params) => <TextField {...params} style={{ background: couleurPrincipalBlanc }} label={'Rechercher'} />}
                options={this.state.data}
                onInputChange={(event, valeur) => {
                  this.setState({ chargement: true, filtreInformation: valeur });
                  this.fetchData(valeur);
                }}
                noOptionsText={'Aucun Résultat'}
                inputStyle={raw.rechercheTexte}
                onChange={(event, item) => {
                  this.setState({
                    actuel: item && item.bbox ? item : null,
                    filtreInformation: item && item.bbox ? item.place_name : null
                  });
                }}
                getOptionLabel={item => item.place_name}
              />
          </View>
        </View>
      </Modal>
    );
  }
}
