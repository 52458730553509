import { StyleSheet } from 'react-native-web';
import { hauteurRef, redimHauteur } from '../../../libs/utils';

export const raw = {
  filtre: {
    height: redimHauteur(hauteurRef - (96 + 56))
  },
  sticker_boutons: {
    top: redimHauteur(56)
  }
};

export default StyleSheet.create(raw);
