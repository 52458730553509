import React, { Component } from 'react';
import Modal from '../../../../modal/rendu';
import styles, { raw } from './style';
import { Text } from 'react-native-web';
import { MapboxStyleURL } from '../../../../../libs/utils';
import ReactMapboxGL from 'react-mapbox-gl';
import { connect } from 'react-redux';

const MapView = ReactMapboxGL({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  interactive: false
});

class ModalLayers extends Component {
  options;

  constructor (props) {
    super(props);

    this.options = [
      {
        styleURL: MapboxStyleURL.Dark,
        label: 'Sombre'
      },
      {
        styleURL: MapboxStyleURL.Light,
        label: 'Lumineux'
      },
      {
        styleURL: MapboxStyleURL.Outdoors,
        label: 'Extérieur'
      },
      {
        styleURL: MapboxStyleURL.Satellite,
        label: 'Satellite'
      },
      {
        styleURL: MapboxStyleURL.SatelliteStreet,
        label: 'Satellite avec tracé'
      },
      {
        styleURL: MapboxStyleURL.Street,
        label: 'Rue'
      },
      {
        styleURL: MapboxStyleURL.TrafficDay,
        label: 'Circulation'
      },
      {
        styleURL: MapboxStyleURL.TrafficNight,
        label: 'Circulation de nuit'
      }
    ];

    this.state = {
      actuel: this.props.actuel
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.actuel !== this.props.actuel) {
      this.setState({ actuel: nextProps.actuel });
    }

    return true;
  }

  render () {
    return (
      <Modal
        boutonUnique={true}
        scrollable={false}
        isVisible={this.props.isVisible}
        titre={'Couche'}
        onHide={this.props.onHide}>
        {this.options.map(element => <Modal.Valeur style={styles.pickerConteneurOption} onClick={() => {
          this.props.update(element.styleURL);

          if (typeof this.props.onHide === 'function') {
            this.props.onHide();
          }
        }}>
          <Text>{element.label}</Text>
          <MapView
          onStyleLoad={map => map.resize()}
          zoom={[1]} center={[0, 0]} style={element.styleURL} containerStyle={raw.carteEncadrement} className={'attribute-hide'} />
        </Modal.Valeur>)}
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    update: element =>
      dispatch({
        reference: 'LAYER',
        type: 'UPDATE',
        styleURL: element
      })
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ModalLayers);
