import React, { Component } from 'react';
import { View } from 'react-native-web';
import styles from './style';
import { couleurPrincipalVert } from '../../../../libs/utils';

export default class Filtre extends Component {
  constructor (props) {
    super(props);
    this.state = { indiceFocus: -1 };
  }

  render () {
    return (
        <View style={[styles.conteneur_filtre, this.props.style]}>
          <View style={styles.contenu_filtre}>
            {React.Children.map(this.props.children, (enfant, indice) => {
              if (enfant) {
                return React.cloneElement(enfant, {
                  couleurNom: couleurPrincipalVert,
                  sousGroupe: true,
                  focus: this.state.indiceFocus === indice,
                  onClick: () => {
                    this.setState({ indiceFocus: indice });
                    if (enfant.props && enfant.props.onClick) {
                      enfant.props.onClick();
                    }
                  }
                });
              }

              return null;
            })}
          </View>
        </View>
    );
  }
}
