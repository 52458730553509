import React, { Component } from 'react';
import { View } from 'react-native-web';
import { raw } from './style';

export default class Sticker extends Component {
  render () {
    return (
        <View
            style={{
              ...raw.filtre_boutons,
              ...this.props.style,
              ...(!this.props.actif ? raw.cacher : {})
            }}
        >
          {React.Children.map(this.props.children, (enfant, indice) => {
            if (indice < React.Children.count(this.props.children) - 1) {
              return React.cloneElement(enfant, {
                style: {
                  ...raw.filtre_bouton_avant_dernier,
                  ...raw.filtre_bouton
                }
              });
            } else {
              return React.cloneElement(enfant,
                { style: raw.filtre_bouton });
            }
          })}
        </View>
    );
  }
}
