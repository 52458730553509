import React, { PureComponent as Component } from 'react';
import Modal from '../../../../../modal/rendu';
import styles, { raw } from './style';
import { Text } from 'react-native-web';
import ModalPage from './page/rendu';
import { Button } from 'react-bootstrap';

export default class ModalTableau extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isVisible: true,
      indicePage: null,
      actuel: this.props.tableau
        ? this.props.tableau.map(() => {
          return {
            nom: null,
            lieu: null,
            lignes: [],
            point: null
          };
        })
        : null
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.tableau !== this.props.tableau && nextProps.tableau) {
      this.setState({
        actuel: nextProps.tableau
          ? nextProps.tableau.map(() => {
            return {
              nom: null,
              lieu: null,
              lignes: [],
              point: null
            };
          })
          : [],
        indicePage:
          nextProps.tableau && nextProps.tableau.length === 1 ? 0 : null
      });
    }

    return true;
  }

  render () {
    return [
      <Modal
        unique={this.props.update}
        validationDisabled={
          !this.props.tableau ||
          this.props.tableau.length === 0 ||
          !this.state.actuel ||
          this.state.actuel.reduce((valPrecedent, valActuel) => {
            return (
              (valActuel.point === null ||
                valActuel.nom === null ||
                valActuel.lieu === null ||
                valActuel.lignes.length === 0) &&
              valPrecedent
            );
          }, true)
        }
        isVisible={
          this.state.isVisible &&
          this.props.isVisible &&
          this.props.tableau &&
          (this.props.tableau.length > 1 ||
            (this.props.update && this.state.indicePage === null))
        }
        onValidate={() => {
          if (typeof this.props.onValidate === 'function') {
            this.props.onValidate(this.state.actuel);
          }
        }}
        titre={'Pages'}
        onHide={this.props.update ? this.props.onCancel : null}
        onCancel={this.props.onCancel}>
        {this.props.tableau
          ? this.props.tableau.map((val, indice) => {
            return (
                  <Button
                    variant={'dark'}
                    style={raw.bouton}
                    onClick={() => {
                      this.setState({ indicePage: indice, isVisible: false });
                    }}>
                    <Text style={styles.boutonTexte}>
                      {val.nom ? val.nom : `Page ${indice + 1}`}
                    </Text>
                  </Button>
            );
          })
          : null}
      </Modal>,
      <ModalPage
        update={this.props.update}
        unique={
          this.props.tableau &&
          (this.props.tableau.length === 1 || this.props.update)
        }
        isVisible={
          this.state.actuel && typeof this.state.indicePage === 'number'
        }
        actuel={
          this.state.actuel && typeof this.state.indicePage === 'number'
            ? this.state.actuel[this.state.indicePage]
            : null
        }
        page={
          this.props.tableau &&
          this.state.actuel &&
          typeof this.state.indicePage === 'number'
            ? this.props.tableau[this.state.indicePage].feuille
            : null
        }
        onValidate={() => {
          if ((this.props.tableau.length === 1 || this.props.update) && typeof this.props.onValidate === 'function') {
            this.props.onValidate(this.state.actuel);
          }
        }}
        onCancel={() => {
          if (this.props.tableau.length === 1 && typeof this.props.onCancel === 'function') {
            this.props.onCancel();
          }
        }}
        onHide={() => {
          this.setState({
            indicePage: null,
            isVisible: true
          });
        }}
      />
    ];
  }
}
