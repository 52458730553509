import React from 'react';
import { View } from 'react-native-web';
import styles from './App.style';
import Accueil from './components/pages/accueil/rendu';
import Carte from './components/pages/carte/rendu';
import { Provider } from 'react-redux';
import Store from './store';
import HttpsRedirect from 'react-https-redirect';
import SideMenu from './components/pages/sidemenu/rendu';
import * as SecureStorage from './libs/secure-storage';

class App extends React.Component {
  constructor (props) {
    super(props);
    SecureStorage.init();
  }

  render () {
    return (
      <HttpsRedirect>
        <Provider store={Store}>
            <View style={styles.conteneur}>
              <Accueil>
                <SideMenu />
                <Carte />
              </Accueil>
            </View>
        </Provider>
      </HttpsRedirect>
    );
  }
}

export default App;
