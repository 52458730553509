import React, { Component } from 'react';
import Modal from '../../../../modal/rendu';
import { TextInput } from 'react-native-web';

export default class ModalNom extends Component {
  textInput;

  constructor (props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      actuel: this.props.actuel
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.actuel !== this.props.actuel) {
      this.setState({ actuel: nextProps.actuel });
    }

    return true;
  }

  render () {
    return (
      <Modal
        onHide={this.props.onHide}
        validationDisabled={!this.state.actuel}
        isVisible={this.props.isVisible}
        onShow={() => {
          this.textInput.current.focus();
        }}
        onValidate={() => {
          if (typeof this.props.onValidate === 'function') {
            const actuel = this.state.actuel;
            this.setState({ actuel: this.props.actuel });
            this.props.onValidate(actuel);
          }
        }}
        titre={this.props.entete || 'Nom'}
        onCancel={this.props.onCancel}>
        <Modal.Valeur>
          <TextInput
            ref={this.textInput}
            style={{ height: 64, fontSize: 24 }}
            autoCapitalize={'none'}
            autoCompleteType={'off'}
            autoCorrect={false}
            textContentType={'none'}
            value={this.state.actuel}
            placeholder={this.props.actuel}
            onChangeText={nom => {
              this.setState({ actuel: nom ? nom.trim() : nom });
            }}
          />
        </Modal.Valeur>
      </Modal>
    );
  }
}
