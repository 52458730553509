import React, { PureComponent as Component } from 'react';
import Modal from '../../../../../../../modal/rendu';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

export default class ModalPoint extends Component {
  constructor (props) {
    super(props);
    this.state = this.props.actuel
      ? this.props.actuel
      : { support: null, x: null, y: null };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.actuel !== this.props.actuel) {
      this.setState(
        nextProps.actuel ? nextProps.actuel : { support: null, x: null, y: null }
      );
    }

    return true;
  }

  render () {
    const items = this.props.page && this.props.page.length > 0
      ? this.props.page[0].map((val, indice) => {
        return <MenuItem value={indice}>{val.valeur}</MenuItem>;
      })
      : null;

    return (
      <Modal
        validationDisabled={
          this.state.support === null ||
          this.state.x === null ||
          this.state.y === null
        }
        isVisible={this.props.isVisible}
        onHide={this.props.onHide}
        onValidate={() => {
          if (typeof this.props.onValidate === 'function') {
            const point = {
              support: this.state.support,
              x: this.state.x,
              y: this.state.y
            };

            this.setState(
              this.props.actuel
                ? this.props.actuel
                : { support: null, x: null, y: null }
            );

            this.props.onValidate(point);
          }
        }}
        titre={'Point'}
        onCancel={this.props.onCancel}>
        <Modal.Entete>Support</Modal.Entete>
        <FormControl
          style={{ width: '100%' }}>
          <InputLabel id={'label-point-support'}>Selectionner une colonne</InputLabel>
          <Select
            labelId={'label-point-support'}
            autoWidth={true}
            value={this.state.support}
            onChange={event => {
              this.setState({ support: event.target.value });
            }}>
            {items}
          </Select>
        </FormControl>
        <Modal.Entete>Longitude (X)</Modal.Entete>
        <FormControl
          style={{ width: '100%' }}>
          <InputLabel id={'label-point-longitude'}>Selectionner une colonne</InputLabel>
          <Select
            labelId={'label-point-longitude'}
            autoWidth={true}
            value={this.state.x}
            onChange={event => {
              this.setState({ x: event.target.value });
            }}>
            {items}
          </Select>
        </FormControl>
        <Modal.Entete>Latitude (Y)</Modal.Entete>
        <FormControl
          style={{ width: '100%' }}>
          <InputLabel id={'label-point-latitude'}>Selectionner une colonne</InputLabel>
          <Select
            labelId={'label-point-latitude'}
            autoWidth={true}
            value={this.state.y}
            onChange={event => {
              this.setState({ y: event.target.value });
            }}>
            {items}
          </Select>
        </FormControl>
      </Modal>
    );
  }
}
