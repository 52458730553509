import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurSecondaireBlanc,
  hauteurRef,
  largeurRef,
  redimHauteur,
  redimLargeur
} from '../../../../libs/utils';

export const raw = {
  cacher: {
    display: 'none'
  },
  conteneur_filtre: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    justifyContent: 'space-between',
    width: redimLargeur(largeurRef / 3.5),
    height: redimHauteur(hauteurRef),
    backgroundColor: couleurSecondaireBlanc,
    borderRightWidth: redimLargeur(1),
    borderRightColor: couleurBordurePrincipal,
    zIndex: 1000
  },
  conteneur_filtre_boutons: {
    height: redimHauteur(64),
    width: redimLargeur(largeurRef / 3.5),
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTopColor: couleurBordurePrincipal,
    borderTopWidth: redimLargeur(1),
    backgroundColor: couleurPrincipalBlanc,
    borderRightWidth: redimLargeur(1),
    borderRightColor: couleurBordurePrincipal,
    flexDirection: 'row'
  },
  conteneur_filtre_bouton: {
    height: redimHauteur(56),
    width: redimLargeur(128 + 48),
    padding: 0,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: redimHauteur(1),
    borderColor: couleurPrincipalBlanc
  },
  conteneur_filtre_bouton_texte: {
    fontSize: redimHauteur(18),
    color: couleurPrincipalBlanc,
    lineHeight: 0,
    textTransform: 'uppercase'
  },
  filtre_icone: {
    paddingRight: redimLargeur(6)
  }
};

export default StyleSheet.create(raw);
