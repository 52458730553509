const initialState = {
  ouvrage: null,
  priorite: null,
  support: null
};

export default (state = initialState, action) => {
  if (
    action.reference &&
    action.reference.toUpperCase() === 'FILTRE'
  ) {
    switch (action.type) {
      case 'UPDATE':
        return { ...state, ...action.filtre };

      default:
        return state;
    }
  }

  return state;
};
