import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurPrincipalBleu,
  couleurPrincipalGris,
  couleurSecondaireBlanc,
  hauteurRef,
  largeurRef,
  redimHauteur,
  redimLargeur
} from '../../../libs/utils';

export const raw = {
  conteneur: {
    width: redimLargeur(largeurRef),
    height: redimHauteur(hauteurRef - (96 + 56)),
    backgroundColor: couleurPrincipalBlanc
  },
  conteneur_carte: {
    width: redimLargeur(largeurRef),
    height: redimHauteur(hauteurRef - (96 + 56))
  },
  conteneurChargement: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  listeInformation: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    borderTopColor: couleurBordurePrincipal,
    borderTopWidth: 0.25
  },
  informationContenu: {
    height: redimHauteur(28)
  },
  informationContenuBordure: {
    borderTopWidth: 0.25,
    borderTopColor: couleurBordurePrincipal,
    borderTopStyle: 'solid'
  },
  informationTitre: {
    fontSize: redimHauteur(14),
    fontWeight: 'bold',
    paddingLeft: redimHauteur(16),
    paddingRight: redimHauteur(16),
    backgroundColor: couleurPrincipalGris,
    color: couleurPrincipalBlanc
  },
  informationValeur: {
    backgroundColor: couleurSecondaireBlanc,
    fontSize: redimHauteur(14),
    paddingLeft: redimHauteur(16),
    paddingRight: redimHauteur(16)
  },
  information: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: redimHauteur(64),
    width: redimLargeur(largeurRef),
    backgroundColor: couleurSecondaireBlanc
  },
  rechercheTexte: {
    backgroundColor: couleurPrincipalBlanc,
    borderColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    width: '100%'
  },
  pickerConteneurOption: {
    height: redimHauteur(96),
    borderBottomColor: 'grey',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 8,
    flexDirection: 'row'
  },
  suppressionRecherche: {
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center'
  },
  conteneurRecherche: { flex: 1 },
  conteneurHaut: {
    position: 'absolute',
    top: 0,
    width: '100%',
    flexDirection: 'row-reverse'
  },
  conteneurBas: { position: 'absolute', bottom: 0, width: '100%' },
  carteEncadrement: {
    width: '50%',
    height: '100%'
  },
  rechercheConteneur: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0
  },
  recherche: {
    width: '100%',
    backgroundColor: couleurSecondaireBlanc,
    borderWidth: 0.25,
    borderColor: couleurBordurePrincipal
  },
  bouton: {
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: couleurPrincipalBleu
  },
  boutonTexte: {
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold'
  },
  iconeBoutonDynamique: {
    height: 48,
    width: 48,
    borderRadius: 25,
    backgroundColor: couleurPrincipalBlanc,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    marginRight: 8
  },
  boutonDynamique: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  }
};

export default StyleSheet.create(raw);
