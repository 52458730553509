export default class Bounds {
  min;
  max;

  constructor () {
    this.min = null;
    this.max = null;
  }

  isValide () {
    return this.min !== null && this.max !== null;
  }

  static isInstance (bounds) {
    return (
      bounds instanceof Bounds ||
      (typeof bounds.max === 'object' &&
        typeof bounds.min === 'object' &&
        typeof bounds.max.x === 'number' &&
        typeof bounds.max.y === 'number' &&
        typeof bounds.min.x === 'number' &&
        typeof bounds.min.y === 'number')
    );
  }

  encapsulate (point) {
    if (Bounds.isInstance(point)) {
      if (point.min !== null && point.max !== null) {
        this.encapsulate(point.min);
        this.encapsulate(point.max);
      }
    } else if (point !== null) {
      if (this.min === null) {
        this.min = { x: point.x, y: point.y };
      } else {
        if (this.min.x > point.x) {
          this.min.x = point.x;
        }

        if (this.min.y > point.y) {
          this.min.y = point.y;
        }
      }

      if (this.max === null) {
        this.max = { x: point.x, y: point.y };
      } else {
        if (this.max.x < point.x) {
          this.max.x = point.x;
        }

        if (this.max.y < point.y) {
          this.max.y = point.y;
        }
      }
    }
  }

  contains (point) {
    return (
      this.isValide() &&
      point !== null &&
      this.max.x >= point.x &&
      this.max.y >= point.y &&
      this.min.x <= point.x &&
      this.min.y <= point.y
    );
  }

  static equals (bounds1, bounds2) {
    return (
      (!bounds1 && !bounds2) ||
      (bounds1 &&
        bounds2 &&
        ((!bounds1.isValide() && !bounds2.isValide()) ||
          (bounds1.isValide() &&
            bounds2.isValide() &&
            bounds1.max.x === bounds2.max.x &&
            bounds1.max.y === bounds2.max.y &&
            bounds1.min.x === bounds2.min.x &&
            bounds1.min.y === bounds2.min.y)))
    );
  }
}
