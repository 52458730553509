import { StyleSheet } from 'react-native-web';
import { redimHauteur, redimLargeur } from '../../../libs/utils';

export const raw = {
  conteneur_logo: {
    width: redimLargeur(96),
    height: redimHauteur(90)
  }
};

export default StyleSheet.create(raw);
