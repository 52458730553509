/* eslint one-var: 0, semi-style: 0, no-buffer-constructor: 0 */

// -- Node modules
import * as XLSX from 'xlsx';
import { Buffer } from 'buffer';
import { ValueType } from 'exceljs';
import * as Workbook from './exceljs';
import { derniereColonne, derniereLigne, premiereColonne, premiereLigne } from './utils';

/**
 * Reads the excel file and returns an excel buffer.
 *
 * @function(arg1, arg2)
 * @private
 * @param {String}        the name of the database,
 * @param {Function}      the callback to call at completion,
 * @throws                throws an error if the database doesn't exist,
 *                        is unreachable or is corrupted,
 */
function _readDB (db, callback) {
  // Is db defined?
  if (!db) {
    return callback('This database doesn\'t exist!');
  }

  // Ok. Is this a valid database?
  // eslint-disable-next-line no-undef
  const fileReader = new FileReader();
  fileReader.onload = () => {
    /* istanbul ignore next */
    if (!fileReader.result) {
      return callback('This database seems corrupted!');
    }

    const data = Buffer.from(fileReader.result);
    callback(null, data);
  };

  fileReader.readAsArrayBuffer(db);
}

/**
 * Reads and decode EXCEL database contents.
 *
 * @function(arg1, arg2)
 * @private
 * @param {String}        the name of the database,
 * @param {Function}      the callback to call at completion,
 * @returns {Object}      the excel record inside a Javascript object,
 * @throws                throws an error if the record type isn't decoded or unknown,
 *
 * @description           excel record
 *
 * DELETED RECORD:
 *   1 byte          T: type (always 0),
 *   4 bytes         L: length of this record in bytes (including the T and L fields),
 *   L−5 bytes       bytes to ignore (content undefined),
 *
 * SKIPPER RECORD:
 *   1 byte          T: type (always 1),
 *   4 bytes         number of bytes in the file, including and starting at this
 *                   record, that contain data for POI enclosed in the given
 *                   rectangle,
 *   4 bytes         X1: longitude coordinate of the west edge of the rectangle,
 *   4 bytes         Y1: latitude coordinate of the south edge of the rectangle,
 *   4 bytes         X2: longitude coordinate of the east edge of the rectangle,
 *   4 bytes         Y2: latitude coordinate of the north edge of the rectangle,
 *
 * SIMPLE POI RECORD:
 *   1 byte          T: type (always 2),
 *   4 bytes         L: length of this record in bytes (including the T and L fields),
 *   4 bytes         X: longitude coordinate of the POI,
 *   4 bytes         Y: latitude coordinate of the POI,
 *   L−13 bytes      Name: zero−terminated ASCII string specifying the name
 *                   of the POI,
 *
 * EXTENDED POI RECORD:
 *   1 byte          T: type (always 3),
 *   4 bytes         L: length of this record in bytes (including the T and L fields),
 *   4 bytes         X: longitude coordinate of the POI,
 *   4 bytes         Y: latitude coordinate of the POI,
 *   P bytes         Name: zero−terminated ASCII string specifying the name
 *                   of the POI,
 *   Q bytes         Unique ID: zero−terminated string specifying the unique ID
 *                   of the POI,
 *   L−P−Q−13 bytes  Extra data: zero−terminated string, not used yet,
 */
function _readExcel (db, callback) {
  // Read the database contents:
  _readDB(db, (err, excel) => {
    if (err) {
      console.error(err);
    } else {
      const workbookBrute = XLSX.read(excel, { type: 'buffer' });
      const data = Buffer.from(
        XLSX.write(workbookBrute, {
          type: 'array',
          bookType: 'xlsx'
        })
      );

      Workbook.load(data).then(
        workbook => {
          const records = [];
          for (const worksheet of workbook.worksheets) {
            const indicePremiereLigne = premiereLigne(worksheet);
            const indiceDerniereLigne = derniereLigne(worksheet);

            if (indicePremiereLigne <= indiceDerniereLigne) {
              const indicePremiereColonne = premiereColonne(worksheet);
              const indiceDerniereColonne = derniereColonne(worksheet);
              const indice = records.length;
              records[indice] = { nom: worksheet.name, feuille: [] };

              for (let j = indicePremiereLigne; j <= indiceDerniereLigne; j++) {
                records[indice].feuille[j - indicePremiereLigne] = [];

                for (
                  let k = indicePremiereColonne;
                  k <= indiceDerniereColonne;
                  k++
                ) {
                  let valeur;
                  let cellule = worksheet.getCell(j, k);

                  if (
                    cellule.type !== ValueType.String &&
                        cellule.type !== ValueType.Null
                  ) {
                    while (cellule.type === ValueType.Merge) {
                      cellule = cellule.master;
                    }
                  }

                  switch (cellule.type) {
                    case ValueType.RichText:
                      valeur = cellule.value.richText
                        .map(richText => richText.text)
                        .join('\n');
                      break;
                    case ValueType.SharedString:
                    case ValueType.Boolean:
                    case ValueType.Date:
                    case ValueType.Number:
                    case ValueType.String:
                      valeur = cellule.value;
                      break;
                    case ValueType.Hyperlink:
                      valeur = cellule.value.hyperlink;
                      break;
                    case ValueType.Formula:
                      valeur = cellule.value.result;
                      break;
                    default:
                      valeur = null;
                      break;
                  }

                  records[indice].feuille[j - indicePremiereLigne][
                    k - indicePremiereColonne
                  ] = {
                    ligne: worksheet.getCell(j, k).row,
                    colonne: worksheet.getCell(j, k).col,
                    valeur:
                          typeof valeur === 'string'
                            ? valeur.replace(/_x000D_/gi, '\n')
                            : valeur
                  };
                }
              }
            }
          }

          callback(null, records);
        },
        err => {
          callback(err, null);
        }
      );
    }
  });
}

/* eslint-enable no-underscore-dangle */

// -- Public methods -----------------------------------------------------------

/**
   * Returns the database contents.
   *
   * @function (arg1, arg2)
   * @public
   * @param {String}      the database name,
   * @param {Function}    the callback function to call at completion,
   * @returns {Object}    returns a promise,
   */
export function getRecord (db, callback) {
  return new Promise((resolve, reject) => {
    _readExcel(db, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
      if (callback) {
        callback(err, data);
      }
    });
  });
}
