import React, { Component } from 'react';
import { Text, View } from 'react-native-web';
import styles from '../filtreTitre/style';
import stylesNative, { raw as rawNative } from './style';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { redimHauteur } from '../../../libs/utils';
import uniqid from 'uniqid';

export default class ModificationTitre extends Component {
  render () {
    if (React.Children.count(this.props.children)) {
      return (
          <View style={stylesNative.conteneur}>
            {this.props.bouton
              ? (
                  this.props.bouton instanceof Array
                    ? (
                        this.props.bouton.map((element, indice) => {
                          return (
                          <OverlayTrigger
                              placement={'right'}
                              overlay={
                                <Tooltip id={`${uniqid()}-${indice}`}>
                                  {element.titre}
                                </Tooltip>
                              }
                          >
                            <Button
                                onClick={element.onClick}
                                variant={element.variant}
                                style={{
                                  ...rawNative.entete_bouton,
                                  ...rawNative.entete_icone
                                }}
                            >
                              <FontAwesomeIcon
                                  icon={element.icone}
                                  style={rawNative.entete_icone}
                              />
                            </Button>
                          </OverlayTrigger>
                          );
                        })
                      )
                    : (
                    <OverlayTrigger
                        placement={'right'}
                        overlay={
                          <Tooltip id={uniqid()}>
                            {this.props.bouton.titre}
                          </Tooltip>
                        }
                    >
                      <Button
                          onClick={this.props.bouton.onClick}
                          variant={this.props.bouton.variant}
                          style={{
                            ...rawNative.entete_bouton,
                            ...rawNative.entete_icone
                          }}
                      >
                        <FontAwesomeIcon
                            icon={this.props.bouton.icone}
                            style={rawNative.entete_icone}
                        />
                      </Button>
                    </OverlayTrigger>
                      )
                )
              : null}
            <View
                style={[
                  styles.contenu_filtre_titre,
                  {
                    width: `calc(100% - ${redimHauteur(
                        64 *
                        (this.props.bouton
                            ? this.props.bouton instanceof Array
                                ? this.props.bouton.length
                                : 1
                            : 0)
                    )})`
                  }
                ]}
            >
              <Text style={styles.contenu_filtre_titre_texte}>
                {this.props.children}
              </Text>
            </View>
          </View>
      );
    }

    return null;
  }
}
