import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurPrincipalBleu,
  couleurSecondaireBlanc
} from '../../../../../libs/utils';

export const raw = {
  conteneur: {
    flex: 1,
    backgroundColor: couleurPrincipalBlanc
  },
  conteneurChargement: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  listeInformation: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    borderTopColor: couleurBordurePrincipal,
    borderTopWidth: 0.25
  },
  information: {
    flexGrow: 1,
    textAlign: 'center',
    textAlignVertical: 'center',
    height: 32,
    fontSize: 10,
    backgroundColor: couleurPrincipalBlanc,
    borderLeftColor: couleurBordurePrincipal,
    borderLeftWidth: 0.25
  },
  rechercheTexte: {
    backgroundColor: couleurPrincipalBlanc,
    borderColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    width: '100%'
  },
  pickerConteneurOption: {
    cursor: 'pointer',
    height: 64,
    borderBottomColor: 'grey',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 8,
    flexDirection: 'row'
  },
  suppressionRecherche: {
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center'
  },
  conteneurRecherche: { flex: 1 },
  conteneurHaut: {
    position: 'absolute',
    top: 0,
    width: '100%',
    flexDirection: 'row-reverse'
  },
  conteneurBas: { position: 'absolute', bottom: 0, width: '100%' },
  carteEncadrement: {
    width: '50%',
    height: '100%'
  },
  rechercheConteneur: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0
  },
  recherche: {
    width: '100%',
    backgroundColor: couleurSecondaireBlanc,
    borderWidth: 0.25,
    borderColor: couleurBordurePrincipal
  },
  bouton: {
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: couleurPrincipalBleu
  },
  boutonTexte: {
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold'
  },
  iconeBoutonDynamique: {
    height: 48,
    width: 48,
    borderRadius: 25,
    backgroundColor: couleurPrincipalBlanc,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    marginRight: 8
  },
  boutonDynamique: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  }
};

export default StyleSheet.create(raw);
