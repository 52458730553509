import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles, { raw } from './style';
import { ActivityIndicator, View } from 'react-native-web';
import { couleurPrincipalBlanc } from '../../../libs/utils';
import Text from 'react-native-web/dist/exports/Text';
import uniqid from 'uniqid';

export default class StickerBouton extends Component {
  render () {
    const element = this.props.chargement
      ? (
        <ActivityIndicator
            style={raw.entete_icone}
            color={couleurPrincipalBlanc}
        />
        )
      : (
        <FontAwesomeIcon
            icon={this.props.icone}
            style={raw.entete_icone}
        />
        );

    return (
        <View styles={styles.conteneur}>
          {this.props.entete
            ? (
              <View
                  className={`bg-${this.props.variant || 'primary'}`}
                  style={[
                    styles.conteneur_entete,
                    this.props.styleConteneurEntete]}
              >
                <Text style={styles.entete}>{this.props.entete}</Text>
              </View>
              )
            : null}
          <OverlayTrigger
              placement={'right'}
              overlay={<Tooltip
                  id={uniqid()}>{this.props.titre}</Tooltip>}
          >
            <Button
                href={this.props.lienExterne}
                variant={this.props.variant || 'primary'}
                style={{ ...raw.bouton, ...this.props.style }}
                onClick={this.props.onClick}
            >
              {element}
            </Button>
          </OverlayTrigger>
        </View>
    );
  }
}
