import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Jexcel from '../../../../libs/jexcel';
import * as SecureStorage from '../../../../libs/secure-storage';
import ModalTableau from './modal/tableau/rendu';
import Bounds from '../../../../libs/bounds';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modification from '../../../elements/pages/htb/modification/rendu';
import ModificationTitre from '../../../elements/modificationTitre/rendu';
import ModalCarte from './modal/update/rendu';
import ModalAlerteConfirmation from '../../../elements/modal/alerte/confirmation/rendu';

class GestionnaireCarte extends Component {
  constructor (props) {
    super(props);

    this.state = {
      charger: true,
      item: null,
      visibiliteTableau: false,
      visibiliteCarte: false,
      visibiliteSuppression: false
    };
  }

  charger (donnees, feuille) {
    SecureStorage.get('carte_liste', [])
      .then(async listeActuel => {
        const element = {
          bounds: null,
          timestamp: null,
          lieu: donnees.lieu,
          nom: donnees.nom.toUpperCase()
        };

        element.bounds = new Bounds();
        element.timestamp = Date.now();

        const existe = await SecureStorage.exists(`carte_${element.nom}`);

        if (!existe) {
          listeActuel.push(element.nom);
        }

        element.points = feuille.slice(1).map(ligne => {
          const numPylone = String(ligne[donnees.point.support].valeur).replace(
            /[^\d]+/g,
            ''
          );

          return {
            nom: ligne[donnees.point.support].valeur
              ? String(ligne[donnees.point.support].valeur).trim()
              : ligne[donnees.point.support].valeur,
            x: ligne[donnees.point.x].valeur,
            y: ligne[donnees.point.y].valeur,
            indicePylone: numPylone.length > 0 ? Number(numPylone) : null
          };
        });

        element.lignes = donnees.lignes;

        for (const ligne of element.lignes) {
          for (const point of ligne.points) {
            element.bounds.encapsulate(point);
          }
        }

        for (const point of element.points) {
          element.bounds.encapsulate(point);
        }

        SecureStorage.set(`carte_${element.nom}`, element)
          .then(() => {
            if (!existe) {
              SecureStorage.set('carte_liste', listeActuel)
                .then(() => {
                  this.props.add(element);
                  this.props.updateTimestampBBOX();
                })
                .catch(e => {
                  console.error(e);
                });
            } else {
              this.props.update(element);
              this.props.updateTimestampBBOX();
            }
          })
          .catch(e => {
            console.error(e);
          });
      })
      .catch(e => {
        console.error(e);
      });
  }

  getDonnees () {
    const donnees = [];

    for (const nom of this.props.carte.liste) {
      if (this.props.carte.donnees[nom]) {
        donnees.push(this.props.carte.donnees[nom]);
      }
    }

    return donnees;
  }

  supprimer (nom) {
    SecureStorage.remove(`carte_${nom}`)
      .then(() => SecureStorage.get('carte_liste'))
      .then(liste =>
        SecureStorage.set(
          'carte_liste',
          liste.filter(nomActuel => nomActuel !== nom)
        )
      )
      .then(() => {
        const filtre = (this.props.filtre.ouvrage || []).filter(
          item => item !== nom
        );
        this.props.updateFiltre({ ouvrage: filtre.length > 0 ? filtre : null });
      })
      .then(() => {
        this.props.delete(nom);
        this.props.updateTimestampBBOX();
      });
  }

  render () {
    return [
      <Modification
        chargement={!this.state.charger}
        actif={this.props.actif}
        onClose={this.props.onClose}
        style={this.props.style}
        onLoad={fichier => {
          this.setState({ charger: false });
          Jexcel.getRecord(fichier)
            .then(worksheets => {
              this.setState({ charger: true });
              if (worksheets.length > 0) {
                this.setState({
                  visibiliteTableau: true,
                  worksheets,
                  item: null
                });
              }
            })
            .catch(e => {
              console.error(e);
              this.setState({ charger: true });
            });
        }}
        accept={['.xlsx', '.xls']}>
        {this.getDonnees().map(item => {
          return (
            <ModificationTitre
              bouton={[
                {
                  icone: faTimes,
                  titre: 'Supprimer',
                  variant: 'danger',
                  onClick: () => {
                    this.setState({ visibiliteSuppression: true, item });
                  }
                },
                {
                  icone: faEdit,
                  titre: 'Editer',
                  onClick: () => {
                    this.setState({ item, visibiliteCarte: true });
                  }
                }
              ]}>
              {item.nom}
            </ModificationTitre>
          );
        })}
      </Modification>,
      <ModalTableau
        isVisible={this.state.visibiliteTableau}
        tableau={this.state.worksheets}
        onValidate={donnees => {
          this.setState({ visibiliteTableau: false });
          for (let i = 0; i < donnees.length; i++) {
            if (
              donnees[i].nom !== null &&
              donnees[i].lieu !== null &&
              donnees[i].point !== null &&
              donnees[i].lignes.length > 0
            ) {
              this.charger(donnees[i], this.state.worksheets[i].feuille);
            }
          }
        }}
        onCancel={() => {
          this.setState({ visibiliteTableau: false });
        }}
      />,
      <ModalCarte
        isVisible={this.state.visibiliteCarte}
        item={this.state.item}
        onHide={() => {
          this.setState({ visibiliteCarte: false });
          this.forceUpdate();
        }}
      />,
      <ModalAlerteConfirmation
        visible={this.state.visibiliteSuppression}
        onValidate={() => {
          this.supprimer(this.state.item.nom);
        }}
        onHide={() => {
          this.setState({ visibiliteSuppression: false });
        }}
        titre={'Suppression'}
        labelValidation={'Supprimer'}
        variant={'danger'}>
        Supprimer l'ouvrage {this.state.item ? this.state.item.nom : ''} ?
      </ModalAlerteConfirmation>
    ];
  }
}

const mapStateToProps = state => ({
  carte: state.carte,
  chargement: state.chargement,
  filtre: state.filtre
});

const mapDispatchToProps = dispatch => {
  return {
    add: element =>
      dispatch({
        reference: 'CARTE',
        type: 'ADD',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    updateTimestampBBOX: () =>
      dispatch({
        reference: 'TIMESTAMP',
        type: 'BBOX'
      }),
    delete: element =>
      dispatch({
        reference: 'CARTE',
        type: 'DELETE',
        nom: typeof element === 'string' ? element : null,
        donnees: typeof element !== 'string' ? element : null
      }),
    updateFiltre: element =>
      dispatch({
        reference: 'FILTRE',
        type: 'UPDATE',
        filtre: element
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionnaireCarte);
