import { StyleSheet } from 'react-native-web';
import { couleurPrincipalGris } from '../../../../libs/utils';

export default StyleSheet.create({
  conteneur: {
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: couleurPrincipalGris
  },
  texte: {
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold'
  }
});
