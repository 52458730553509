import React, { Component } from 'react';
import { View } from 'react-native-web';
import styles from './style';
import Entete from '../../elements/entete/rendu';
import EnteteLogo from '../../elements/enteteLogo/rendu';

export default class Accueil extends Component {
  render () {
    return [
      <Entete>
        <EnteteLogo/>
      </Entete>,
      <View style={styles.conteneur}>
        {this.props.children}
      </View>
    ];
  }
}
