import React, { PureComponent as Component } from 'react';
import Modal from '../../../../../../modal/rendu';
import styles, { raw } from './style';
import { ActivityIndicator, Text } from 'react-native-web';
import ModalPoint from './point/rendu';
import ModalLieu from '../../../../modal/lieu/rendu';
import ModalNom from '../../../../modal/nom/rendu';
import * as Jdxf from '../../../../../../../libs/jdxf';
import { Button } from 'react-bootstrap';
import ReactFileInput from '../../../../../../fichier/ReactFileInput';
import { couleurPrincipalBleu } from '../../../../../../../libs/utils';

export default class ModalPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      charger: true,
      isVisible: true,
      visibilitePoint: false,
      visibiliteLieu: false,
      visibiliteNom: false,
      actuel: this.props.actuel
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (nextProps.actuel !== this.state.actuel) {
      this.setState({
        actuel: nextProps.actuel
      });
    }

    return true;
  }

  render () {
    return [
      <Modal
        validationDisabled={
          !this.state.actuel ||
          !this.state.actuel.point ||
          ((!this.state.actuel.nom || !this.state.actuel.lieu) &&
            !this.props.update) ||
          !this.state.actuel.lignes.length
        }
        boutonUnique={!this.props.unique}
        isVisible={
          this.state.isVisible &&
          this.props.isVisible
        }
        titre={'Page'}
        onHide={this.props.onHide}
        onValidate={this.props.onValidate}
        onCancel={this.props.onCancel}>

        {this.state.charger
          ? [!this.props.update
              ? [
                <Button
                  variant={'dark'}
                  style={raw.bouton}
                  onClick={() => {
                    this.setState({ visibiliteNom: true, isVisible: false });
                  }}>
                  <Text style={styles.boutonTexte}>Nom d'ouvrage</Text>
                </Button>,
                <Button
                  variant={'dark'}
                  style={raw.bouton}
                  onClick={() => {
                    this.setState({ visibiliteLieu: true, isVisible: false });
                  }}>
                  <Text style={styles.boutonTexte}>Lieu</Text>
                </Button>
                ]
              : null,
          <ReactFileInput onLoad={fichier => {
            this.setState({ charger: false });
            Jdxf.getRecord(fichier)
              .then(lignes => {
                this.state.actuel.lignes = lignes;
                this.setState({ charger: true });
              })
              .catch(() => {
                this.setState({ charger: true });
              });
          }}
          accept={'.dxf'}>
          <Button
            variant={'dark'}
            style={raw.bouton}>
            <Text style={styles.boutonTexte}>Lignes</Text>
          </Button>
          </ReactFileInput>,
          <Button
            variant={'dark'}
            style={raw.bouton}
            onClick={() => {
              this.setState({ visibilitePoint: true, isVisible: false });
            }}>
            <Text style={styles.boutonTexte}>Point</Text>
          </Button>
            ]
          : <ActivityIndicator size="large" color={couleurPrincipalBleu} />}
      </Modal>,
      <ModalPoint
        isVisible={this.state.visibilitePoint}
        actuel={this.state.actuel ? this.state.actuel.point : null}
        page={this.props.page}
        onValidate={point => {
          this.state.actuel.point = point;
          this.setState({
            visibilitePoint: false
          });
        }}
        onCancel={() => {
          this.setState({ visibilitePoint: false });
        }}
        onHide={() => {
          this.setState({ isVisible: true });
        }}
      />,
      <ModalLieu
        isVisible={this.state.visibiliteLieu}
        actuel={this.state.actuel ? this.state.actuel.lieu : null}
        onValidate={lieu => {
          this.state.actuel.lieu = lieu;
          this.setState({
            visibiliteLieu: false
          });
        }}
        onCancel={() => {
          this.setState({ visibiliteLieu: false });
        }}
        onHide={() => {
          this.setState({ isVisible: true });
        }}
      />,
      <ModalNom
        isVisible={this.state.visibiliteNom}
        entete={"Nom d'ouvrage"}
        actuel={this.state.actuel ? this.state.actuel.nom : null}
        onValidate={nom => {
          this.state.actuel.nom = nom;
          this.setState({
            visibiliteNom: false
          });
        }}
        onCancel={() => {
          this.setState({ visibiliteNom: false });
        }}
        onHide={() => {
          this.setState({ isVisible: true });
        }}
      />
    ];
  }
}
