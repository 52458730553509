import { StyleSheet } from 'react-native-web';
import {
  couleurBordurePrincipal,
  couleurPrincipalBlanc,
  couleurSecondaireBlanc,
  couleurTertiaireBlanc,
  redimHauteur
} from '../../../../../../libs/utils';

export const raw = {
  conteneur: {
    flex: 1,
    backgroundColor: couleurPrincipalBlanc
  },
  conteneurChargement: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  bouton: {
    height: redimHauteur(56),
    width: '100%',
    padding: 0,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: redimHauteur(1),
    borderColor: couleurPrincipalBlanc
  },
  boutonTexte: {
    fontSize: redimHauteur(18),
    color: couleurPrincipalBlanc,
    lineHeight: 0,
    textTransform: 'uppercase'
  },
  boutonIcone: {
    width: '50%',
    height: '50%'
  },
  sousBouton: {
    height: 64,
    width: 64,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: couleurBordurePrincipal,
    borderLeftColor: couleurBordurePrincipal,
    borderRightColor: couleurBordurePrincipal,
    borderBottomWidth: 0.25,
    borderLeftWidth: 0.25,
    borderRightWidth: 0.25
  },
  sousBoutonConteneur: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    paddingVertical: 8
  },
  sousBoutonTexte: {
    color: 'white',
    fontSize: 10,
    textAlign: 'center'
  },
  swipeViewListe: {
    height: 64,
    backgroundColor: couleurPrincipalBlanc,
    borderBottomColor: couleurBordurePrincipal,
    borderBottomWidth: 0.25,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  swipedViewSousListe: {
    height: 64,
    flexDirection: 'row'
  },
  swipeViewActif: {
    backgroundColor: couleurPrincipalBlanc
  },
  swipeViewInactif: {
    backgroundColor: couleurTertiaireBlanc
  },
  listeBoutons: {
    width: '100%',
    flexDirection: 'row'
  },
  swipeViewCouleur: {
    height: 64,
    width: 64,
    borderBottomWidth: 0.25,
    borderLeftWidth: 0.25,
    borderBottomColor: couleurBordurePrincipal,
    borderLeftColor: couleurBordurePrincipal
  },
  swipeView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 64,
    width: '100%',
    alignItems: 'center',
    backgroundColor: couleurPrincipalBlanc,
    borderBottomColor: couleurBordurePrincipal,
    borderLeftColor: couleurBordurePrincipal,
    borderRightColor: couleurBordurePrincipal,
    borderBottomWidth: 0.25,
    borderLeftWidth: 0.25,
    borderRightWidth: 0.25
  },
  swipeViewTexte: {
    color: couleurBordurePrincipal,
    fontSize: 18
  },
  liste: {
    flex: 1,
    backgroundColor: couleurSecondaireBlanc
  }
};

export default StyleSheet.create(raw);
