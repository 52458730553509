import { StyleSheet } from 'react-native-web';
import { couleurBordurePrincipal, redimHauteur } from '../../../libs/utils';

export const raw = {
  cacher: {
    display: 'none'
  },
  filtre_boutons: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    top: 0
  },
  filtre_bouton: {
    padding: 0,
    lineHeight: 0,
    height: redimHauteur(64),
    width: redimHauteur(64),
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderRightColor: couleurBordurePrincipal,
    borderBottomColor: couleurBordurePrincipal
  },
  filtre_bouton_avant_dernier: {
    borderBottomRightRadius: 0
  }
};

export default StyleSheet.create(raw);
